import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminRecord from '../../components/AdminRecord';
import AdminTabs from '../../components/AdminTabsUnboxed';
import { AdminToastTimed } from '../../components/AdminToasts';
import TriggerActivityConditions from '../../components/triggers/ActivityConditions';
import TriggerContentConditions from '../../components/triggers/ContentConditions';
import TriggerProfileConditions from '../../components/triggers/ProfileConditions';
import { uuidv4 } from '../../utils/uuid';
import TriggerActionsTemplate from './detail/actions';
import TriggerHeader from './detail/header';
import TriggerRunHistoryTemplate from './detail/history';
const TriggerDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { config, adminTriggerApi } = useBlueprintAdmin();
  const location: any = useLocation();
  const [trigger, setTrigger] = useState<any>();
  const [toastController, setToastController]: any = useState(null);

  useEffect(() => {
    setToastController(location.state?.detail ?? '');
  }, [location.state?.detail]);

  useEffect(() => {
    if (id) {
      adminTriggerApi!
        .getTrigger(id)
        .then(({ data }) => {
          setTrigger(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <>
      {toastController?.includes('savedTrigger') && (
        <AdminToastTimed
          message={'Trigger settings have been updated'}
          trigger={toastController?.includes('savedTrigger') ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      {id && config && trigger && (
        <AdminPage animation={true} title={`Manage Trigger`}>
          {trigger.status === 'active' ? (
            <Grid container direction='column'>
              <Grid item>
                <Button
                  variant='text'
                  color='primary'
                  to={`/triggers`}
                  style={{ padding: '0', marginBottom: '20px' }}
                  component={Link}
                >
                  <ArrowBackIcon />
                  &nbsp;&nbsp;ALL TRIGGERS
                </Button>
              </Grid>
              <TriggerHeader trigger={trigger} />
              <AdminCard title={'Trigger Settings'}>
                <AdminTabs
                  removeTitle
                  defaultTab={location.state?.detail === 'viewHistory' ? 2 : 0}
                  tabs={[
                    {
                      title: 'Conditions',
                      template: (
                        <AdminRecord
                          url={`/admin/triggers/${id}`}
                          itemTemplate={(trigger) => (
                            <>
                              {trigger.type === 'activity' && (
                                <TriggerActivityConditions trigger={trigger} />
                              )}
                              {trigger.type === 'content' && (
                                <TriggerContentConditions trigger={trigger} />
                              )}
                              {trigger.type === 'profile' && (
                                <TriggerProfileConditions trigger={trigger} />
                              )}
                            </>
                          )}
                        />
                      ),
                    },
                    {
                      title: 'Actions',
                      template: <TriggerActionsTemplate id={id} />,
                    },
                    {
                      title: 'Run History',
                      template: (
                        <>
                          {trigger.type === 'activity' && (
                            <TriggerRunHistoryTemplate
                              id={id}
                              showUser={true}
                            />
                          )}
                          {trigger.type === 'profile' && (
                            <TriggerRunHistoryTemplate
                              id={id}
                              showUser={true}
                            />
                          )}
                          {trigger.type === 'content' && (
                            <TriggerRunHistoryTemplate
                              id={id}
                              showUser={false}
                            />
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </AdminCard>
            </Grid>
          ) : (
            <Typography variant='h6'>This Trigger does not exist.</Typography>
          )}
        </AdminPage>
      )}
    </>
  );
};

export default TriggerDetail;
