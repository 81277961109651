import { ConfigUserAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link as UiLink,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../../components/AdminTable';

const ConfigAll = () => {
  const { config, adminApi } = useBlueprintAdmin();
  const navigate = useNavigate();
  const configureUserAPI = new ConfigUserAPI(config);
  const [versions, setVersions] = useState<any>([]);

  useEffect(() => {
    adminApi
      ?.getDeployedServiceVersions()
      .then(({ data }) => {
        setVersions(data.services);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const deleteConfig = async (entity, id) => {
    const response = await configureUserAPI.removeIdentity(entity, id);
    if (response.success) {
      navigate(`/config/all`);
    }
  };

  const configTemplate = ({ id, updatedAt, entity }, index) => (
    <AdminTableRow key={index}>
      <TableCell>
        <UiLink component={Link} to={`/config/beacon/${id}`}>
          {id}
        </UiLink>
      </TableCell>
      <TableCell>{entity}</TableCell>
      <TableCell>Updated {new Date(updatedAt).toLocaleString()}</TableCell>
      <TableCell>
        <UiLink
          component={Link}
          data-testid={`${id}-delete`}
          to='#'
          onClick={async () => {
            await deleteConfig(entity, id);
          }}
        >
          Delete
        </UiLink>
      </TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage animation={true} title='Config'>
      <AdminCard title='All Current Service Versions'>
        <Table style={{ marginTop: 10 }}>
          <AdminTableHead>
            <TableRow>
              <AdminTableHeadCell>Service</AdminTableHeadCell>
              <AdminTableHeadCell>Version</AdminTableHeadCell>
            </TableRow>
          </AdminTableHead>
          <TableBody>
            {versions.map((version, index) => (
              <AdminTableRow key={index}>
                <TableCell>{version.name}</TableCell>
                <TableCell>{version.image.split(':')[1]}</TableCell>
              </AdminTableRow>
            ))}
          </TableBody>
        </Table>
      </AdminCard>
      <AdminPaginatedList
        title='All Current Configs'
        columns={['id', 'entity', 'updatedAt', 'actions']}
        url='/admin/config'
        itemTemplate={configTemplate}
      />
    </AdminPage>
  );
};

export default ConfigAll;
