import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminAssetLink from '../../components/AdminAssetLink';
import AdminAssetViewer from '../../components/AdminAssetViewer';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import AdminRecord from '../../components/AdminRecord';
import AdminUserInfo from '../../components/AdminUserInfo';

const AssetDetail = () => {
  const { id } = useParams<{ id: string }>();

  const itemTemplate = (data) => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>{data.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Admin</TableCell>
          <TableCell>
            <AdminUserInfo id={data.adminId} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>User</TableCell>
          <TableCell>{data.userId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Entity</TableCell>
          <TableCell>{data.entityType}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Entity ID</TableCell>
          <TableCell>{data.entityId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Asset Viewer</TableCell>
          <TableCell>
            <AdminAssetViewer id={id} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Download Asset</TableCell>
          <TableCell>
            <AdminAssetLink id={id!} caption={'Download Asset'} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <>
      {id && (
        <AdminPage animation={true} title={`Asset: ${id}`}>
          <AdminCard title='Detail'>
            <AdminRecord
              form={false}
              url={`/admin/assets/${id}`}
              itemTemplate={itemTemplate}
            />
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};

export default AssetDetail;
