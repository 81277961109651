import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminCard from '../components/AdminCard';
import AdminPage from '../components/AdminPage';
import { useBlueprintAdmin } from '../components/AdminProvider';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../components/AdminTable';

const Dashboard = () => {
  const navigate = useNavigate();
  const { jwtToken, config, adminApi } = useBlueprintAdmin();
  const [versions, setVersions] = useState<any>([]);

  useEffect(() => {
    adminApi
      ?.getDeployedServiceVersions()
      .then(({ data }) => {
        setVersions(data.services);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Bug fix, resolve authToken not set
  useEffect(() => {
    if (config.authMethod.type === 'okta') {
      if (config.oktaLoginInstance.getIdToken() && !jwtToken) {
        navigate('/auth/login');
      }
    }
  }, []);

  return (
    <>
      <AdminPage title='Dashboard'>
        <AdminCard title='Your Current Blueprint Services'>
          <Table style={{ marginTop: 10 }}>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Service</AdminTableHeadCell>
                <AdminTableHeadCell>Version</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {versions.map((version, index) => (
                <AdminTableRow key={index}>
                  <TableCell>{version.name}</TableCell>
                  <TableCell>{version.image.split(':')[1]}</TableCell>
                </AdminTableRow>
              ))}
            </TableBody>
          </Table>
        </AdminCard>
      </AdminPage>
    </>
  );
};

export default Dashboard;
