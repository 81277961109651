import {
  Box,
  Button,
  Container,
  CssBaseline,
  Drawer,
  Grid,
  Hidden,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AdminCard from './AdminCard';
import AdminSidebar from './AdminSidebar';
import Breadcrumbs, { Breadcrumb } from './Breadcrumbs';

const drawerWidth = 140;

interface IAdminPage {
  title?: any | undefined;
  children?: any;
  header?: any;
  description?: string;
  newAction?: string;
  newActionText?: string;
  editAction?: string;
  deleteAction?: string;
  customActionText?: string;
  customAction?: any;
  secondaryCustomActionText?: string;
  secondaryCustomAction?: any;
  animation?: boolean;
  sidebar?: any;
  showSidebar?: boolean;
  breadcrumbs?: Breadcrumb[];
}

const AdminPage = ({
  title = undefined,
  children,
  description = '',
  header,
  newAction = '',
  newActionText = 'New +',
  editAction = '',
  deleteAction = '',
  customActionText = '',
  customAction = '',
  secondaryCustomActionText = '',
  secondaryCustomAction = '',
  animation = false,
  sidebar,
  showSidebar = true,
  breadcrumbs = [],
}: IAdminPage) => {
  const controlTemplate = () => (
    <>
      {breadcrumbs.length > 0 && (
        <Box mb={2}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
      )}
      {title && (
        <Toolbar style={{ padding: 0 }}>
          <Typography variant='h4' style={{ flex: 1, paddingBottom: 20 }}>
            {title}
          </Typography>
          <div>
            {newAction && (
              <Link to={`${newAction}`} style={{ textDecoration: 'none' }}>
                <Button
                  style={{ paddingLeft: 10 }}
                  variant='contained'
                  color='primary'
                >
                  {newActionText}
                </Button>
              </Link>
            )}
            {editAction && (
              <Link to={`${editAction}`}>
                <Button
                  style={{ paddingLeft: 10 }}
                  variant='contained'
                  color='primary'
                >
                  Edit
                </Button>
              </Link>
            )}
            {deleteAction && (
              <Link to={`${deleteAction}`}>
                <Button
                  style={{
                    backgroundColor: '#d32f2f',
                    color: '#fff',
                    paddingLeft: 10,
                  }}
                >
                  Delete
                </Button>
              </Link>
            )}
            {customAction &&
              typeof customAction === 'function' &&
              customActionText && (
                <Button
                  style={{ margin: 2, paddingLeft: 10 }}
                  onClick={() => customAction()}
                >
                  {customActionText}
                </Button>
              )}
            {secondaryCustomAction &&
              typeof secondaryCustomAction === 'function' &&
              secondaryCustomActionText && (
                <Button
                  style={{ margin: 2, paddingLeft: 10 }}
                  onClick={() => secondaryCustomAction()}
                >
                  {secondaryCustomActionText}
                </Button>
              )}
          </div>
        </Toolbar>
      )}
    </>
  );

  return (
    <>
      <div style={{ display: 'flex', paddingBottom: '40px' }}>
        {showSidebar && (
          <Hidden smDown implementation='js'>
            <nav aria-label='sidebar'>
              <Drawer
                variant='permanent'
                anchor='left'
                style={{ width: drawerWidth }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                open
              >
                <AdminSidebar />
              </Drawer>
            </nav>
          </Hidden>
        )}

        <div style={{ flex: 1, flexGrow: 1, paddingTop: 20 }}>
          {header && (
            <Container maxWidth='xl' style={{ marginTop: 20, marginBottom: 5 }}>
              {breadcrumbs && <Box mb={2}>{breadcrumbs}</Box>}
              {header}
            </Container>
          )}

          <Container maxWidth='xl'>
            <main
              style={{
                flex: 1,
                flexGrow: 1,
                paddingTop: 20,
              }}
            >
              <Grid>
                {!header && <>{controlTemplate()}</>}
                <Grid>
                  {!header && description && (
                    <Grid item xs={12} style={{ paddingBottom: 20 }}>
                      {description}
                    </Grid>
                  )}
                  {!sidebar && (
                    <Grid
                      container
                      direction='column'
                      justifyContent='space-between'
                    >
                      {children}
                    </Grid>
                  )}
                  {sidebar && (
                    <>
                      <Grid item md={9}>
                        {children}
                      </Grid>
                      <Grid item xs={3}>
                        <AdminCard>
                          <h3>This</h3>
                        </AdminCard>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </main>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
