import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import QueryBuilder from 'react-querybuilder';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from '../../utils/uuid';
import AdminCard from '../AdminCard';
import { useBlueprintAdmin } from '../AdminProvider';
import AdminSupportSidebar from '../AdminSupportSidebar';
import { AdminToolTip } from '../AdminToolTip';
import {
  MaterialActionElement,
  MaterialNotToggle,
  MaterialValueEditor,
  MaterialValueSelector,
} from './MaterialConditions';

const useStyles = makeStyles((theme: Theme) => ({
  addGroup: {
    margin: 20,
    color: 'green',
    borderColor: 'green',
  },
  addRule: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  removeGroup: {
    color: 'red',
    borderColor: 'red',
  },
  removeRule: {
    marginTop: 20,
    color: 'red',
    borderColor: 'red',
  },
}));

const TriggerProfileConditions = ({ trigger }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [query, setQuery] = useState(undefined);
  const { adminTriggerApi } = useBlueprintAdmin();

  const fields = [
    {
      name: 'key',
      label: 'Trait Key',
      value: trigger.match && trigger.match.field,
    },
  ];

  useEffect(() => {
    setQuery(trigger.match);
  }, []);

  const submitQuery = async () => {
    const response = await adminTriggerApi!.updateTrigger(trigger.id, {
      match: query,
    });
    if (response.success) {
      navigate('./', {
        state: { detail: 'savedTrigger'.concat(uuidv4()) },
      });
    }
  };

  const handleOnQueryChange = (query) => {
    setQuery(query);
  };

  const saveAction = () => (
    <Button
      variant='outlined'
      color='primary'
      onClick={() => submitQuery()}
      className='btn btn-primary btn-sm float-right'
    >
      Save
    </Button>
  );

  return (
    <Grid container spacing={5}>
      <Grid item xs={8}>
        <AdminCard
          title={
            <>
              Conditions
              <AdminToolTip
                message={
                  <Typography variant='caption'>
                    {
                      'Conditions are the rules that determine whether a trigger will perform a certain action. Conditions can be events, counts, and urls.'
                    }
                  </Typography>
                }
              ></AdminToolTip>
            </>
          }
          customAction={saveAction()}
        >
          <QueryBuilder
            fields={fields}
            showCombinatorsBetweenRules={true}
            controlClassnames={{
              addGroup: classes.addGroup,
              addRule: classes.addRule,
              removeGroup: classes.removeGroup,
              removeRule: classes.removeRule,
              // combinators: "form-control-m",
              // fields: "form-control form-control-m",
              // operators: "form-control-m",
              // value: "form-control-m",
            }}
            controlElements={{
              addGroupAction: MaterialActionElement,
              addRuleAction: MaterialActionElement,
              combinatorSelector: MaterialValueSelector,
              fieldSelector: MaterialValueSelector,
              notToggle: MaterialNotToggle,
              operatorSelector: MaterialValueSelector,
              removeGroupAction: MaterialActionElement,
              removeRuleAction: MaterialActionElement,
              valueEditor: MaterialValueEditor,
            }}
            query={query}
            onQueryChange={handleOnQueryChange}
          />
        </AdminCard>
      </Grid>
      <Grid item xs={4}>
        <AdminSupportSidebar title='How do I use this?'>
          <strong>Profile Triggers</strong> are run every time a user&apos;s
          profile is updated either through direct entry or through a backend
          process, such as when user enters their firstName in a form.
          <br />
          <br />
          The job of the <strong>Profile Trigger Condition</strong>is to provide
          the conditions that we will match against to determine if we should
          perform an action.
          <br />
          <br />A common example is matching <strong>Field</strong> to the value
          of <strong>email</strong>, this means that we will run the defined
          actions of this trigger only when a user has added an email to their
          profile. Commonly used for sending welcome or retargeting emails.
        </AdminSupportSidebar>
      </Grid>
    </Grid>
  );
};

export default TriggerProfileConditions;
