import { IOneTrustTrackerConfig } from '@deloitte-us-consulting-dd/blueprint-beacon';
import { Grid } from '@mui/material';
import { FormInputRadio } from '../../form-components/FormInputRadio';
import {
  DefaultBeaconOptions,
  oneTrustTrackerDDOptions,
} from '../TrackerConstants';

export const setOneTrustTrackerPrevConfig = (savedData, name) => {
  const oneTrustTrackerSettings: IOneTrustTrackerConfig = savedData;
  const form_data_obj: any = {
    ...DefaultBeaconOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };
  if (oneTrustTrackerSettings.settings) {
    form_data_obj.mode = oneTrustTrackerSettings.settings.mode;
  }
  return form_data_obj;
};

export const userOneTrustSetting = (form_data) => {
  if (form_data.mode === '') {
    return { mode: 'addTrait' };
  } else {
    return { mode: form_data.mode };
  }
};

export const setOneTrustOnSubmit = (form_data, updatedData) => {
  form_data.mode = updatedData.mode;
};

export const OneTrustTrackerForm = (control, setValue, onSubmit, form_data) => {
  return (
    <Grid container spacing={1}>
      <Grid item spacing={1} sm={12}>
        <FormInputRadio
          name={'mode'}
          control={control}
          label={''}
          setValue={setValue}
          optionsList={oneTrustTrackerDDOptions}
          defaultValue={form_data.mode}
          submitfunction={onSubmit}
        />
      </Grid>
    </Grid>
  );
};
