import {
  Button,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IBeaconConfig,
  IFacebookTrackerConfig,
  IOneTrustTrackerConfig,
  IPageTrackerConfig,
  ISessionTrackerConfig,
  IUserTrackerConfig,
} from '../../../../api/interfaces';
import { useBlueprintAdmin } from '../../../../components/AdminProvider';
import AdminTableCard from '../../../../components/AdminTableCard';
import { uuidv4 } from '../../../../utils/uuid';
import BeaconGridSettingsCard from '../BeaconGridSettingsCard';
import {
  DefaultBeaconOptions,
  alwaysEnabledTrackers,
} from './TrackerConstants';
import {
  setDataLayerTrackerPrevConfig,
  userDataLayerSettingFormChange,
} from './tracker-components/DataLayerTrackerComponent';
import {
  setFbPixelPrevConfig,
  userFBSettingFormChange,
} from './tracker-components/FacebookTrackerComponent';
import {
  setOneTrustTrackerPrevConfig,
  userOneTrustSetting,
} from './tracker-components/OneTrustTrackerComponent';
import {
  setPageTrackerPrevConfig,
  userPageSettingFormChange,
} from './tracker-components/PageTrackerComponent';
import {
  setSessionTrackerPrevConfig,
  userSessionSetting,
} from './tracker-components/SessionTrackerComponent';
import {
  setUserTrackerPrevConfig,
  userDataSettingFormChange,
} from './tracker-components/UserTrackerComponent';

const TrackerSelectorGrid = ({
  showSettings,
  onChange,
  beacon,
  onlyShowSelected,
  displayType,
}: any) => {
  const [savedTrackers, setSavedTrackers] = useState<
    Array<
      | IUserTrackerConfig
      | ISessionTrackerConfig
      | IFacebookTrackerConfig
      | IPageTrackerConfig
      | IOneTrustTrackerConfig
    >
  >([]);

  useEffect(() => {
    setSavedTrackers(beacon?.trackers ?? alwaysEnabledTrackers);
  }, [beacon]);

  const [trackerOptions, setTrackerOptions] = useState<Array<any>>([
    ...DefaultBeaconOptions,
  ]);
  const [gridDisplayType, setGridDisplayType] = useState(displayType);
  const [specificTracker, setSpecificTracker] = useState(null);
  const { config, adminActivityApi }: any = useBlueprintAdmin();
  const [runCount, setRunCount] = useState(0);
  const navigate = useNavigate();

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      backgroundColor: 'transparent',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    tabHeader: {
      paddingTop: 5,
      backgroundColor: theme.palette.grey[100],
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    // handle filtering shown trackers for  new vs edit beacon screens
    if (onlyShowSelected) {
      const filteredDefaultOptions = DefaultBeaconOptions.filter((value) =>
        savedTrackers.map((a) => a.name).includes(value.id)
      );
      setTrackerOptions(filteredDefaultOptions);
      setupPrevConfig(filteredDefaultOptions);
      if (gridDisplayType === 'Card') {
        setRunCount(0);
      }
    } else if (specificTracker) {
      const filteredDefaultOptions = DefaultBeaconOptions.filter(
        (value) => value.id === specificTracker
      );
      setTrackerOptions(filteredDefaultOptions);
      setGridDisplayType('Card');
      setupPrevConfig(filteredDefaultOptions);
    } else {
      if (savedTrackers && savedTrackers.length > 0 && runCount <= 0) {
        if (gridDisplayType === 'Card') {
          setRunCount(1);
        }
        const newlist = [...DefaultBeaconOptions];
        setupPrevConfig(newlist);
      }
    }
  }, [savedTrackers, onlyShowSelected, specificTracker]);

  const onBackTotrackers = async () => {
    setSpecificTracker(null);
    setGridDisplayType('Table');
    if (adminActivityApi) {
      const response = await adminActivityApi.getBeacon(beacon.id);
      if (response.success) {
        setSavedTrackers(response.data.trackers);
      } else {
        console.error('error getting beacon trackers');
      }
    }
  };

  // const onReset = () => {
  //   console.log(
  //     'This does nothing at the moment waiting to see how we want to handle this'
  //   );
  // };

  const onSave = async () => {
    const data: IBeaconConfig = {
      trackers: savedTrackers,
    };
    if (adminActivityApi) {
      const response = await adminActivityApi.updateBeacon(beacon.id, data);
      if (response.success) {
        navigate('./', {
          state: { detail: 'BeaconTrackerSaved'.concat(uuidv4()) },
        });
      } else {
        console.error('error saving trackers');
      }
    }
  };

  const setupPrevConfig = (options) => {
    const updatedOptions = [...options];
    savedTrackers.forEach((saved) => {
      options.map((option, i) => {
        if (option.id === saved.name) {
          let updatedObj = {};
          updatedObj = { ...option, enabled: true, unique_id: uuidv4() };
          const { name } = saved;
          switch (name) {
            case 'user':
              updatedObj = {
                ...updatedObj,
                form_data: setUserTrackerPrevConfig(saved, name),
              };

              break;
            case 'fbPixel':
              updatedObj = {
                ...updatedObj,
                form_data: setFbPixelPrevConfig(saved, name),
              };
              break;
            case 'page':
              updatedObj = {
                ...updatedObj,
                form_data: setPageTrackerPrevConfig(saved, name),
              };
              break;
            case 'session':
              updatedObj = {
                ...updatedObj,
                form_data: setSessionTrackerPrevConfig(saved, name),
              };
              break;
            case 'oneTrust':
              updatedObj = {
                ...updatedObj,
                form_data: setOneTrustTrackerPrevConfig(saved, name),
              };
              break;
            case 'dataLayer':
              updatedObj = {
                ...updatedObj,
                form_data: setDataLayerTrackerPrevConfig(saved, name),
              };
              break;
            case 'form':
            case 'click':
              break;
          }
          updatedOptions[i] = updatedObj;
        }
      });
    });

    setTrackerOptions(updatedOptions);
  };

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele.name != value;
    });
  };

  const onSelect = (id, selected) => {
    let newOptions = savedTrackers;
    if (selected) {
      newOptions = [...newOptions, { name: id }];
    } else {
      newOptions = arrayRemove(newOptions, id);
    }
    setSavedTrackers(newOptions);
    onChange(newOptions);
  };

  const onFormChange = (form_data, id) => {
    const newOptions = savedTrackers;
    let settingsObj: any = null;
    for (const x of newOptions) {
      if (x.name === id) {
        if (
          form_data.defaultSelected &&
          form_data.defaultSelected != 'advancedSettings'
        ) {
          delete x.settings;
        } else {
          switch (id) {
            case 'user': {
              settingsObj = userDataSettingFormChange(form_data);
              break;
            }
            case 'oneTrust': {
              settingsObj = userOneTrustSetting(form_data);
              break;
            }
            case 'page': {
              settingsObj = userPageSettingFormChange(form_data);
              break;
            }
            case 'dataLayer': {
              settingsObj = userDataLayerSettingFormChange(form_data);
              break;
            }
            case 'session': {
              settingsObj = userSessionSetting(form_data);
              break;
            }
            case 'fbPixel': {
              settingsObj = userFBSettingFormChange(form_data);
              break;
            }
            default:
              settingsObj = form_data;
          }
          if (settingsObj) {
            x.settings = settingsObj;
          } else {
            delete x.settings;
          }
        }
      }
    }
    setSavedTrackers(newOptions);
    onChange(newOptions);
  };

  return (
    <>
      {gridDisplayType === 'Card' && (
        <>
          {trackerOptions.map(
            ({
              id,
              unique_id,
              title,
              description,
              enabled,
              form_data,
              disableToggle,
              toolTip,
            }) => (
              <Grid style={{ paddingTop: 20 }} key={unique_id}>
                <BeaconGridSettingsCard
                  cardType={'Tracker'}
                  showSettings={showSettings}
                  key={unique_id}
                  id={id}
                  onSelect={(id, selected) => onSelect(id, selected)}
                  onFormChange={(form_data, id) => onFormChange(form_data, id)}
                  beacon={beacon}
                  selectable={true}
                  title={title}
                  form_data={form_data}
                  enabled={enabled}
                  disableToggle={disableToggle}
                  toolTip={toolTip}
                >
                  <p>
                    {description}
                    {enabled}
                  </p>
                </BeaconGridSettingsCard>
              </Grid>
            )
          )}
        </>
      )}
      {gridDisplayType === 'Table' && (
        <>
          <TableHead className={classes.tabHeader}>
            <TableRow>
              <TableCell width='25%'>Trackers</TableCell>
              <TableCell width='25%'>Status</TableCell>
              <TableCell
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  minWidth: '400px',
                  wordWrap: 'break-word',
                }}
                width='40%'
                align='left'
              >
                Settings
              </TableCell>
              <TableCell width='10%'></TableCell>
            </TableRow>
          </TableHead>

          {trackerOptions.map(
            ({
              id,
              unique_id,
              title,
              description,
              enabled,
              form_data,
              toolTip,
            }) => (
              <Grid style={{ paddingTop: 20 }} key={unique_id}>
                <AdminTableCard
                  showSettings={showSettings}
                  key={unique_id}
                  id={id}
                  onSelect={(id, selected) => onSelect(id, selected)}
                  onFormChange={(form_data, id) => onFormChange(form_data, id)}
                  onSelectSpecific={(specificTracker) =>
                    setSpecificTracker(specificTracker)
                  }
                  beacon={beacon}
                  selectable={true}
                  title={title}
                  form_data={form_data}
                  enabled={enabled}
                  toolTip={toolTip}
                >
                  <p>
                    {description}
                    {enabled}
                  </p>
                </AdminTableCard>
              </Grid>
            )
          )}
        </>
      )}
      {specificTracker && (
        <Grid container>
          <Grid container item xs={10} justifyContent='flex-start'>
            <Button
              variant='text'
              color='primary'
              onClick={async () => {
                await onBackTotrackers();
              }}
              className={classes.button}
            >
              Back To Trackers
            </Button>
          </Grid>
          <Grid container item xs={2} justifyContent='flex-end'>
            {/* <Grid>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => onReset()}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </Grid> */}
            <Grid>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => await onSave()}
                className={classes.button}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TrackerSelectorGrid;
