import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { CSVReader } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';

const ProspectNew = () => {
  const { config, adminUserApi } = useBlueprintAdmin();

  const buttonRef: any = React.createRef();

  const navigate = useNavigate();

  const saveProspects = async (prospects) => {
    await adminUserApi!.createProspects(prospects);
    navigate(`/prospects`);
  };

  const handleOnFileLoad = async (data) => {
    const prospects: Array<any> = [];

    data.map(function (record: any) {
      console.log(record.data);
      prospects.push(record.data);
    });

    await saveProspects(prospects);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  return (
    <AdminPage title={'Import Prospects'}>
      {buttonRef && (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <AdminCard title='About Prospects'>
              <Typography variant='body1'>
                Prospects are a landing zone for information about users before
                they have been entered into the system. <br />
                <br />
                For example, if you want to import a large set of data that you
                know about your users and then based on their activity or
                opted-in information they provide you with identifiers, this
                information will be pulled into their user profile.
              </Typography>
            </AdminCard>
          </Grid>
          <Grid item xs={12}>
            <AdminCard title='Upload Prospects File'>
              <Typography variant='h6'>Important notes</Typography>
              <Typography variant='body1'>
                <ul>
                  <li>
                    You can upload a prospects file as many times as you would
                    like.
                  </li>
                  <li>
                    When uploading, if a prospect <strong>not found</strong> in
                    the existing dataset, it will be created (based on the
                    external_id and external_source columns).
                  </li>
                  <li>
                    When uploading, if a prospect <strong>is found</strong> in
                    the existing dataset, the data will be updated with the
                    contents of the uploaded file.
                  </li>
                  <li>
                    If this is your first time importing prospects, you can
                    start by using{' '}
                    <a href='/example/prospects.csv' target='_blank'>
                      an example prospect file here.
                    </a>
                  </li>
                  <li>
                    Note that the only required fields are 'external_id' and
                    'external_source', all other fields can be added or extended
                    to fit the key/values you'd like to see on your user
                    profiles.
                  </li>
                </ul>
              </Typography>
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                config={{
                  header: true,
                }}
                noDrag
              >
                {({ file }) => (
                  <Grid container>
                    <Grid item xs={4}>
                      <Button
                        variant='contained'
                        type='button'
                        onClick={handleOpenDialog}
                        style={{
                          width: '100%',
                        }}
                      >
                        Select file
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CSVReader>
            </AdminCard>
          </Grid>
        </Grid>
      )}
    </AdminPage>
  );
};

export default ProspectNew;
