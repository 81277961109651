import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBeaconCreate } from '../../api/interfaces';
import AdminHorizontalFlow from '../../components/AdminHorizontalFlow';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { AdminToastWarning } from '../../components/AdminToasts';
import { AdminToolTip } from '../../components/AdminToolTip';
import { getUserApiRoot } from '../../utils/shared';
import { InstallBeacon } from './BeaconScript';
import { alwaysEnabledTrackers } from './edit/trackerSettings/TrackerConstants';
import TrackerSelectorGrid from './edit/trackerSettings/TrackerSelectorGrid';
const ConfigBeaconNew = () => {
  const navigate = useNavigate();
  const { config, adminActivityApi }: any = useBlueprintAdmin();
  const [selectedOptions, setSelectedOptions] = useState<Array<any>>(
    alwaysEnabledTrackers
  );
  const [showVariable, setShowVariable] = useState(false);
  const [beacon, setBeacon]: any = useState({});
  const [formikValues, setFormikValues] = useState({
    name: '',
    isDebug: 'true',
  });
  const [beaconNameErrorObject, setBeaconNameErrorObject] = useState({
    error: false,
    errorMessage: '',
  });
  const [cancelWarningTrigger, setCancelWarningTrigger]: any = useState(null);
  const [hidden, setHidden] = useState(false);
  let formik;

  const onSubmitDynamicBeacon = async () => {
    setShowVariable(true);
    const debug = formikValues.isDebug === 'true' ? true : false;
    if (debug) {
      const data: IBeaconCreate = {
        name: formikValues.name,
        debug: debug,
        apiRoot: getUserApiRoot(config.apiRoot),
        trackers: selectedOptions,
      };
      const response = await adminActivityApi.createBeacon(data);

      if (response.success) {
        setBeacon(response.data);
        return true;
      } else {
        console.error('error submitting beacon');
        return false;
      }
    }
    return true;
  };

  const onSubmitProductionBeacon = async () => {
    setShowVariable(false);
    const debug = formikValues.isDebug === 'true' ? true : false;
    const data: IBeaconCreate = {
      name: formikValues.name,
      debug: debug,
      apiRoot: getUserApiRoot(config.apiRoot),
      trackers: selectedOptions,
    };
    const response = await adminActivityApi.createBeacon(data);

    if (response.success) {
      setBeacon(response.data);
      return true;
    } else {
      console.error('error submitting beacon');
      return false;
    }
  };

  const beaconErrorController = async () => {
    const response = await adminActivityApi.getBeacons({ limit: 999, page: 1 });
    if (response.success) {
      const mapBeaconName = response.data.map((value) => value.name);

      if (formik.values.name.length === 0) {
        setBeaconNameErrorObject({
          error: true,
          errorMessage: 'Please enter a beacon name',
        });
        return false;
      } else if (mapBeaconName.includes(formik.values.name)) {
        setBeaconNameErrorObject({
          error: true,
          errorMessage: 'Please enter a unique beacon name',
        });
        return false;
      } else {
        setBeaconNameErrorObject({
          error: false,
          errorMessage: '',
        });
        return true;
      }
    } else {
      console.error('error getting beacons');
    }
  };

  const BeaconSetupPage = () => {
    formik = useFormik({
      initialValues: {
        name: formikValues.name,
        isDebug: formikValues.isDebug,
      },
      onSubmit: (values) => {
        console.log('do nothing');
      },
    });

    return (
      <>
        <Typography variant='h4' style={{ flex: 1 }}>
          Select Beacon Name & Mode
        </Typography>
        <form>
          <FormControl
            margin='dense'
            fullWidth
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant='subtitle1'>
                  Enter a name
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        <strong>Name</strong> can be helpful to provide an
                        admin-only label for future reference or referral.{' '}
                        <br />
                        <br /> Typically, this could be a version number (e.g.
                        v1.0.0) or aligned with when you plan to deploy it (e.g.
                        Launch Code Freeze 2021)
                      </Typography>
                    }
                  ></AdminToolTip>
                </Typography>
                <TextField
                  error={beaconNameErrorObject.error}
                  helperText={beaconNameErrorObject.errorMessage}
                  id='name'
                  name='name'
                  type='text'
                  variant='outlined'
                  label='Beacon Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl margin='dense' fullWidth style={{ paddingBottom: 20 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant='subtitle1'>
                  Select a mode
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        <strong>Development</strong> will enable the developer
                        panel, which helps developer debug the experience, as
                        well as added logging to support client implementation=
                        <br />
                        <br />
                        <strong>Production</strong> will be a reduced file size
                        to focus on speed and will not show the developer panel.
                        This should be used in a production environment for real
                        users
                      </Typography>
                    }
                  ></AdminToolTip>
                </Typography>
                <RadioGroup
                  id='isDebug'
                  name='isDebug'
                  defaultValue={formik.values.isDebug}
                  aria-label='debug'
                >
                  <FormControlLabel
                    onChange={formik.handleChange}
                    value='true'
                    control={<Radio />}
                    label='Development'
                  />
                  <FormControlLabel
                    onChange={formik.handleChange}
                    value='false'
                    control={<Radio />}
                    label='Production'
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </>
    );
  };

  const onSubmitBeaconName = async () => {
    setFormikValues(formik.values);
    await beaconErrorController();
    const addSession = !selectedOptions.some((el) => el.name === 'session')
      ? [...selectedOptions, { name: 'session' }]
      : [...selectedOptions];
    setSelectedOptions(
      formik.values.isDebug === 'true' ? addSession : [...alwaysEnabledTrackers]
    );
    return beaconErrorController();
  };

  const onInstallBeaconFinish = () => {
    if (beacon) {
      navigate(`/beacons/${beacon.id}`, {
        state: { detail: 'newBeacon' },
      });
    } else {
      navigate(`/beacons/`);
    }
    return true;
  };

  const onBackupTrackers = () => {
    setShowVariable(false);
    return true;
  };

  const onBackToBeaconName = () => {
    setSelectedOptions(alwaysEnabledTrackers);
  };

  const onBackupToConfig = () => {
    setShowVariable(true);
    return true;
  };

  const defaultbackup = () => {
    console.log('do nothing');
    return true;
  };

  const onCancelAny = () => {
    if (!cancelWarningTrigger) {
      setCancelWarningTrigger(true);
    } else {
      setCancelWarningTrigger(!cancelWarningTrigger);
    }
  };

  const flowConst = [
    {
      title: 'Select Beacon Name & Mode',
      template: <BeaconSetupPage></BeaconSetupPage>,
      onSubmit: onSubmitBeaconName,
      onBackup: defaultbackup,
      onCancel: onCancelAny,
      hidden: false,
    },
    {
      title: 'Select Trackers',
      template: (
        <>
          <Typography variant='h4' style={{ flex: 1, paddingBottom: 20 }}>
            Select Trackers
          </Typography>
          <Typography variant='subtitle1'>
            Enable the trackers you would like to be included in the beacon.
          </Typography>
          <TrackerSelectorGrid
            showSettings={false}
            onChange={(selected) => setSelectedOptions(selected)}
            beacon={{ trackers: selectedOptions }}
            onlyShowSelected={showVariable}
            displayType={'Card'}
          ></TrackerSelectorGrid>
        </>
      ),
      onSubmit: onSubmitDynamicBeacon,
      buttonLabel:
        formikValues.isDebug === 'true'
          ? 'CREATE BEACON'
          : 'CONFIGURE TRACKERS',
      onBackup: onBackToBeaconName,
      onCancel: onCancelAny,
      hidden: false,
    },
    {
      title: 'Configure Trackers',
      template: (
        <>
          <Typography variant='h4' style={{ flex: 1, paddingBottom: 20 }}>
            Select Trackers
          </Typography>
          <Typography variant='subtitle1'>
            Enable the trackers you would like to be included in the beacon.
          </Typography>
          <TrackerSelectorGrid
            showSettings={true}
            onChange={(selected) => setSelectedOptions(selected)}
            beacon={{ trackers: selectedOptions }}
            onlyShowSelected={showVariable}
            displayType={'Card'}
          ></TrackerSelectorGrid>
        </>
      ),
      onSubmit: onSubmitProductionBeacon,
      buttonLabel: 'CREATE BEACON',
      onBackup: onBackupTrackers,
      onCancel: onCancelAny,
      hidden: hidden,
    },
    {
      title: 'Install Beacon',
      template: (
        <>
          <Typography variant='h4' style={{ flex: 1, paddingBottom: 20 }}>
            Install Beacon
          </Typography>
          <Typography variant='subtitle1'>
            This step may be completed now, or you can access this code later
            from the Manage Beacon page.
          </Typography>
          <InstallBeacon
            beacon={beacon}
            isDevelopment={formikValues.isDebug === 'false' ? false : true}
            setSelectedDetailTab={null}
          ></InstallBeacon>
        </>
      ),
      onSubmit: onInstallBeaconFinish,
      onBackup: onBackupToConfig,
      onCancel: onCancelAny,
      hidden: false,
      disableBackButton: true,
      disableCancelButton: true,
    },
  ];

  useEffect(() => {
    if (formikValues.isDebug === 'true') {
      setHidden(true);
    }
    if (formikValues.isDebug != 'true') {
      setHidden(false);
    }
  }, [formikValues]);

  return (
    <>
      {cancelWarningTrigger && (
        <AdminToastWarning
          message={'Are you sure you want to cancel?'}
          exitLocation={'/beacons'}
          trigger={cancelWarningTrigger}
        ></AdminToastWarning>
      )}
      <AdminPage showSidebar={false} title='Create New Beacon'>
        <Grid item xs={12}>
          <AdminHorizontalFlow
            flow={flowConst}
            submitButtonLabel='VERIFY INSTALLATION'
          ></AdminHorizontalFlow>
        </Grid>
      </AdminPage>
    </>
  );
};

export default ConfigBeaconNew;
