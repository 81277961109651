import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { useNavigate } from 'react-router-dom';
import AdminForm from '../../../components/AdminForm';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { schema, uiSchema } from '../../../forms/invites';

const UserInviteNew = () => {
  const { config } = useBlueprintAdmin();
  const navigate = useNavigate();
  const api = new AdminScopedAPI(config, 'UserInvite');

  const onSubmit = async ({ formData }) => {
    const response = await api.post({
      path: '/admin/invites',
      body: {
        external_source: formData.external_source,
        external_id: formData.external_id,
        roles: formData.roles,
        groups: formData.groups,
        anonymousId: formData.existing_user,
      },
    });
    if (response.success) {
      const userId = response.data.userId;
      navigate(`/users/${userId}`);
    } else {
      console.error(response.message);
    }
  };

  return (
    <>
      <AdminPage title={`New Invite Codes`}>
        <AdminForm schema={schema} uiSchema={uiSchema} onSubmit={onSubmit} />
      </AdminPage>
    </>
  );
};

export default UserInviteNew;
