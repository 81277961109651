import { roles, variableRoles } from '../../../config/roles';
import { useNavigate } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { defaultRoles } from '../../../config/sidebar';
import { clearConfigCache } from '../../../config/dynamicConfig';

const fixedUiSchema = {};

const fixedSchema = {
  description:
    "This setting will set the fixed elements in the sidebar that will always be shown, regardless of an administrator's authorization. This is commonly used to place an element of the dashboard at the top of the list and to be seen for everyone, such as for a 'Dashboard' component",
  type: 'object',
  properties: {
    value: {
      title: 'Fixed Sidebar Items',
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
            description:
              'The label of the sidebar item to be shown in the sidebar (e.g. Dashboard)',
          },
          route: {
            type: 'string',
            description:
              'The route of the page (e.g. / or /content or a page such as /users/123)',
          },
          icon: {
            type: 'string',
            description: 'Icon from PUT URL HERE',
          },
        },
      },
    },
  },
};

const roleUiSchema = {};

const roleSchema = {
  type: 'object',
  description: 'This setting will set the role-based elements in the sidebar',
  properties: {
    value: {
      title: 'Role-Based Sidebar Items',
      type: 'array',
      items: {
        type: 'string',
        enum: defaultRoles,
      },
    },
  },
};

const ConfigSidebar = ({ showSidebar = true, isFromSetup = false }: any) => {
  const navigate = useNavigate();

  const onSuccess = (response) => {
    clearConfigCache();
    navigate(`/config`);
  };

  return (
    <>
      <AdminBaseForm
        id='role'
        url='/admin/config/sidebar'
        entityTitle='Role-Based Sidebar Items'
        schema={roleSchema}
        uiSchema={roleUiSchema}
        onSuccess={onSuccess}
        rootValue='value'
        showSidebar={showSidebar}
      />
      <AdminBaseForm
        id='fixed'
        url='/admin/config/sidebar'
        entityTitle='Fixed Sidebar Items'
        schema={fixedSchema}
        uiSchema={fixedUiSchema}
        onSuccess={onSuccess}
        rootValue='value'
        showSidebar={showSidebar}
      />
    </>
  );
};

export default ConfigSidebar;
