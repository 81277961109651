import { useNavigate } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';

const ConfigNew = (formSchema: any = {}, uiSchema: any = {}) => {
  const navigate = useNavigate();

  const onSuccess = (response) => {
    navigate(`/admins/${response.data.id}`);
  };

  return (
    <AdminBaseForm
      url='/admin/config'
      entityTitle='Config'
      schema={formSchema.formSchema}
      uiSchema={uiSchema.uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default ConfigNew;
