import { useNavigate } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { schema } from '../../../forms/userGroups';

const UserGroupNew = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(`/users/group`, {
      state: { detail: 'newUserGroup' },
    });
  };

  return (
    <AdminBaseForm
      url='/admin/user-groups'
      entityTitle='User Group'
      schema={schema}
      onSuccess={onSuccess}
    />
  );
};

export default UserGroupNew;
