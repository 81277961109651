import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Link as UiLink, TableCell, TableRow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminPage from '../../../components/AdminPage';
import AdminPaginatedList from '../../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import AdminTabs from '../../../components/AdminTabs';
import { trimText } from '../../../utils/shared';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    backgroundColor: 'white',
  },
  selectedCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  actionsLink: {
    paddingLeft: 10,
  },
}));

const ContentCollectionDetail = () => {
  const classes = useStyles();
  const { config, jwtToken, adminContentApi } = useBlueprintAdmin();
  const { collectionId } = useParams<{ collectionId: string }>();
  const [content, setContent] = useState<any>();

  useEffect(() => {
    adminContentApi
      ?.getCollection(collectionId!)
      .then(({ data }) => {
        setContent(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [collectionId]);

  const getTitle = (content: any) => {
    if (content.title) {
      return content.title;
    }
    if (content.name) {
      return content.name;
    }
    if (content.firstName && content.lastName) {
      return `${content.firstName} ${content.lastName}`;
    }
  };

  // This is only relevant to content where there is an asset link (e.g. when a video is attached to content)
  const handleAssetDownloadClick = (id) => {
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      url: `${config.apiRoot}/admin/assets/${id}/url`,
    })
      .then((res) => {
        window.open(res.data.data, '_blank');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // This is only relevant to content where there is an asset link (e.g. when a video is attached to content)
  const assetDownloadLink = (content) => (
    <>
      {content.status === 'pending' &&
        content?.full?.attachments?.map((item, index) => (
          <UiLink
            key={`assetDownloadLink-${index}`}
            className={classes.actionsLink}
            onClick={() => handleAssetDownloadClick(item.url)}
          >
            <VideocamIcon />
          </UiLink>
        ))}
      {content.status === 'pending' && content.videoLink && (
        <UiLink
          className={classes.actionsLink}
          onClick={() => handleAssetDownloadClick(content.videoLink)}
        >
          <VideocamIcon />
        </UiLink>
      )}
    </>
  );

  const actions = (collectionId, content) => (
    <>
      {assetDownloadLink(content)}
      <UiLink
        className={classes.actionsLink}
        component={Link}
        to={`/content/collections/${collectionId}/records/${content.id}/edit`}
      >
        <EditIcon />
      </UiLink>
      <UiLink
        className={classes.actionsLink}
        component={Link}
        to={`/content/collections/${collectionId}/records/${content.id}/delete`}
      >
        <DeleteIcon />
      </UiLink>
    </>
  );

  const paginatedListTemplate = (filter = '') => (
    <AdminPaginatedList
      pageSize={20}
      // search={`search/collections/${collectionId}?searchFields=title&returnFields=title`}
      columns={['title', 'createdAt', 'updatedAt', 'status', 'manage']}
      filter={`status=${filter}`}
      url={`/admin/content/collections/${collectionId}/records`}
      itemTemplate={({ content, status, createdAt, updatedAt }, index) =>
        content && (
          <TableRow key={index}>
            <TableCell>
              <UiLink
                component={Link}
                to={`/content/collections/${collectionId}/records/${content.id}`}
              >
                <>{trimText(getTitle(content), 100)}</>
              </UiLink>
            </TableCell>
            <TableCell>
              <Moment fromNow>{createdAt}</Moment>
            </TableCell>
            <TableCell>
              <Moment fromNow>{updatedAt}</Moment>
            </TableCell>
            <TableCell className='status'>{status}</TableCell>
            <TableCell className='d-flex justify-content-end'>
              {actions(collectionId, content)}
            </TableCell>
          </TableRow>
        )
      }
    />
  );

  const tabs: Array<{
    title: string;
    template: React.ReactNode;
    icon?: React.ReactNode;
  }> = [
    {
      title: 'All',
      template: (
        <AdminCard
          title={`All Records In ${content ? content.entity : collectionId}`}
          newAction={`/content/collections/${collectionId}/records/new`}
        >
          {paginatedListTemplate()}
        </AdminCard>
      ),
    },
    {
      title: 'Draft',
      template: (
        <AdminCard
          title={`Draft Records In ${content ? content.entity : collectionId}`}
          newAction={`/content/collections/${collectionId}/records/new`}
        >
          {paginatedListTemplate('draft')}
        </AdminCard>
      ),
    },
    {
      title: 'Published Only',
      template: (
        <AdminCard
          title={`Published Records In ${
            content ? content.entity : collectionId
          }`}
          newAction={`/content/collections/${collectionId}/records/new`}
        >
          {paginatedListTemplate('published')}
        </AdminCard>
      ),
    },
  ];

  return (
    <>
      {collectionId && (
        <AdminPage
          title={`Collection: ${collectionId}`}
          editAction={`/content/collections/${collectionId}/edit`}
        >
          <AdminTabs tabs={tabs} />
        </AdminPage>
      )}
    </>
  );
};

export default ContentCollectionDetail;
