import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import {
  Box,
  Button,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSVDownloader } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import useMounted from '../hooks/useMounted';
import AdminCard from './AdminCard';
import { useBlueprintAdmin } from './AdminProvider';
import { AdminTableHead, AdminTableHeadCell } from './AdminTable';

function AdminPaginatedList({
  title,
  url,
  additionalQueries = '', // for additional queries like `&newQuery=test`
  emptyTemplate,
  itemTemplate,
  columns,
  search,
  filter,
  customAction,
  newAction,
  exportEnabled = false,
  pageSize = 20,
  showCard = true,
  infiniteLoad = true,
}: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [content, setContent] = useState(Array<any>());
  const [hasMore, setHasMore] = useState(false);
  const { config, jwtToken, logout }: any = useBlueprintAdmin();
  const navigate = useNavigate();

  const mounted = useMounted();
  const api = new AdminScopedAPI(config, 'AdminPaginatedList');
  const loadItems = async () => {
    if ((!infiniteLoad && page === 1) || infiniteLoad) {
      let paginatedUrl = `${url}?limit=${pageSize}&page=${page}&order=DESC${additionalQueries}`;

      if (filter) {
        paginatedUrl = paginatedUrl + `&${filter}`;
      }

      const response = await api.get({ path: paginatedUrl });

      if (response.success) {
        const existingData = content;
        existingData.push(...response.data);

        setPage(page + 1);
        setHasMore(response.data.length === pageSize);
        setContent([...existingData]);

        if (response.meta) {
          setTotal(response.meta.total);
        }
        setIsLoading(false);
      } else {
        if (response.status === 401 || 403) {
          logout();
        }
      }
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    loadItems().catch((error) => {
      console.log(error);
    });
  }, [jwtToken]);

  const searchSubmit = (e) => {
    e.preventDefault();
    api
      .post({
        path: `/admin/${search}`,
        body: JSON.stringify({ query: searchTerm }),
      })
      .then((data: any) => {
        setPage(0);
        setHasMore(data.data.length === pageSize);
        setContent([...data.data]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const WithCard = ({ children }) =>
    showCard ? (
      <AdminCard
        title={title}
        customAction={memoizedShowExport}
        newAction={newAction}
      >
        {children}
      </AdminCard>
    ) : (
      children
    );

  const showExportIfEnabled = () => {
    if (exportEnabled) {
      const newContent = content.map((obj) => {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          newObj[key] = JSON.stringify(value);
        });
        return newObj;
      });
      return (
        <CSVDownloader
          data={newContent}
          filename={url}
          bom={true}
          type={'link'}
        >
          <Button variant='outlined' color='primary'>
            Download
          </Button>
        </CSVDownloader>
      );
    } else {
      return null;
    }
  };

  const memoizedShowExport = useMemo(
    () => showExportIfEnabled(),
    [exportEnabled]
  );

  return (
    <>
      <Grid>
        {jwtToken && columns && (
          <AdminCard
            showCard={showCard}
            title={title}
            customAction={memoizedShowExport}
            newAction={newAction}
          >
            <InfiniteScroll
              dataLength={content.length}
              next={loadItems}
              hasMore={hasMore}
              loader={
                <tr className='loader' key={0}>
                  <td>Loading ...</td>
                </tr>
              }
            >
              {search && (
                <form onSubmit={searchSubmit}>
                  <FormGroup>
                    <TextField
                      variant='outlined'
                      id='search'
                      name='search'
                      label='Search'
                      placeholder='Enter search terms'
                      onChange={({ target }) => setSearchTerm(target.value)}
                      style={{ marginTop: 10 }}
                      value={searchTerm}
                    />
                  </FormGroup>
                </form>
              )}

              {isLoading && (
                <Skeleton
                  variant='rectangular'
                  height={250}
                  style={{ marginTop: 10 }}
                />
              )}

              {content && !isLoading && (
                <>
                  <Table style={{ marginTop: 10 }}>
                    <AdminTableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <React.Fragment key={column}>
                            <AdminTableHeadCell>{column}</AdminTableHeadCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </AdminTableHead>
                    <TableBody>
                      {content.length > 0 &&
                        content.map((item: any, index) =>
                          itemTemplate(item, index)
                        )}
                    </TableBody>
                  </Table>
                </>
              )}

              {content && !isLoading && content.length === 0 && (
                <Box style={{ backgroundColor: '#fafafa', padding: 20 }}>
                  {emptyTemplate && (
                    <Typography variant='caption'>{emptyTemplate()}</Typography>
                  )}
                  {!emptyTemplate && (
                    <Typography variant='caption'>No results</Typography>
                  )}
                </Box>
              )}
            </InfiniteScroll>
          </AdminCard>
        )}
      </Grid>
    </>
  );
}

export default AdminPaginatedList;
