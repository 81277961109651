import { ITriggersCreate } from '@deloitte-us-consulting-dd/blueprint-sdk-trigger';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { AdminToastWarning } from '../../components/AdminToasts';
import { AdminToolTip } from '../../components/AdminToolTip';

const NewTrigger = () => {
  const navigate = useNavigate();
  const { adminTriggerApi, adminActivityApi } = useBlueprintAdmin();

  const [cancelWarningTrigger, setCancelWarningTrigger]: any = useState(null);
  const [beacons, setBeacons] = useState<Array<any>>([]);

  useEffect(() => {
    adminActivityApi!
      .getBeacons({ limit: 999, page: 1 })
      .then(({ data }) => {
        setBeacons(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const onSubmitTrigger = async (values) => {
    const data: ITriggersCreate = {
      name: values.name,
      type: values.type,
      beaconId: values.beacon ? JSON.parse(values.triggerBeacon).key : null,
    };

    console.log(JSON.stringify(data));

    const response = await adminTriggerApi!.create(data);

    if (response.success) {
      navigate(`/triggers/${response.data.id}`, {
        state: { detail: 'newTrigger' },
      });
      return true;
    } else {
      console.error(response.message ?? 'error creating trigger');
      return false;
    }
  };

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Please enter a name';
    }

    return errors;
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validate: validate,
    initialValues: {
      name: '',
      type: '',
      beacon: '',
    },
    onSubmit: async (values) => {
      await onSubmitTrigger(values);
    },
  });

  return (
    <>
      {cancelWarningTrigger && (
        <AdminToastWarning
          message={'Are you sure you want to cancel?'}
          exitLocation={'/triggers'}
          trigger={cancelWarningTrigger}
        ></AdminToastWarning>
      )}
      <AdminPage showSidebar={true} title='Create New Trigger'>
        <Grid>
          <AdminCard title='Trigger Details'>
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                margin='dense'
                fullWidth
                style={{ paddingBottom: 20 }}
              >
                <Grid container style={{ overflow: 'visible' }}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      Trigger name
                      <AdminToolTip
                        message={
                          <Typography variant='caption'>
                            Name can be helpful to provide an admin-only label
                            for future reference or referral. Typically, this
                            could be a descriptive name of a workflow.
                          </Typography>
                        }
                      ></AdminToolTip>
                    </Typography>
                    <TextField
                      error={formik.errors.name !== undefined}
                      helperText={formik.errors.name}
                      defaultValue={formik.values.name}
                      value={formik.values.name}
                      id='name'
                      name='name'
                      type='text'
                      variant='outlined'
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
                <FormControl
                  margin='dense'
                  fullWidth
                  style={{ paddingBottom: 20 }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Select a type
                        <AdminToolTip
                          message={
                            <Typography variant='caption'>
                              Activity triggers: Run when activity is collected
                              from a beacon. Profile triggers: Run when a user's
                              profile is updated (usually from a direct API call
                              or database import). Content triggers: Run when
                              content is published in other systems (like a
                              webhook).
                            </Typography>
                          }
                        ></AdminToolTip>
                      </Typography>
                      <RadioGroup
                        id='type'
                        name='type'
                        defaultValue={formik.values.type}
                        value={formik.values.type}
                        aria-label='debug'
                        data-testid='trigger-type-radio'
                      >
                        <FormControlLabel
                          onChange={formik.handleChange}
                          value='activity'
                          control={<Radio />}
                          label='Activity'
                        />
                        <FormControlLabel
                          onChange={formik.handleChange}
                          value='profile'
                          control={<Radio />}
                          label='Profile'
                        />
                        <FormControlLabel
                          onChange={formik.handleChange}
                          value='content'
                          control={<Radio />}
                          label='Content'
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
                {/* <Grid container>
                  <Grid item xs={3}>
                    <Typography variant='subtitle1'>
                      Assign a beacon (Optional)
                      <AdminToolTip
                        message={
                          <Typography variant='caption'>
                            Assign a beacon to your trigger. You should choose
                            the beacon you will use to collect the data. If you
                            haven't created a beacon already, please do so
                            before creating a trigger.
                          </Typography>
                        }
                      ></AdminToolTip>
                    </Typography>
                    <FormControl fullWidth>
                      <Autocomplete
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            'triggerBeacon',
                            JSON.stringify(value)
                          );
                        }}
                        disablePortal={false}
                        options={beacons.map((value) => {
                          return {
                            beaconName: value.name,
                            id: value.id,
                            key: value.key,
                          };
                        })}
                        getOptionLabel={(option) =>
                          `${option.key} - ${option.beaconName}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={triggerError.beaconError}
                            helperText={triggerError.beaconErrorMessage}
                            label='Beacon'
                            name='beacon'
                            id='beacon'
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid> */}
              </FormControl>
              <Button
                variant='contained'
                size='large'
                id='submit'
                type='submit'
              >
                Submit
              </Button>
            </form>
          </AdminCard>
        </Grid>
      </AdminPage>
    </>
  );
};

export default NewTrigger;
