import AdminCard from '../../../components/AdminCard';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ContentRecordDelete = () => {
  const { adminContentApi } = useBlueprintAdmin();
  const navigate = useNavigate();
  const { collectionId, recordId } =
    useParams<{ collectionId: string; recordId: string }>();

  const [record, setRecord] = useState<any>(undefined);

  const handleDelete = async (e) => {
    // SEND TO DELETE
    e.stopPropagation();
    e.preventDefault();

    await adminContentApi
      ?.deleteCollectionRecord(collectionId!, recordId!)
      .then(() => {
        navigate(`/content/collections/${collectionId}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/content/collections/${collectionId}/records/${recordId}`);
  };

  useEffect(() => {
    if (collectionId && recordId) {
      adminContentApi
        ?.getCollectionRecord(collectionId, recordId)
        .then(({ data }) => setRecord(data.content))
        .catch((err) => console.error(err));
    }
  }, []);

  return (
    <>
      {collectionId && record && (
        <AdminPage title='Delete Provider'>
          <AdminCard title=''>
            <h4>
              Are your sure you want to delete{' '}
              <strong>{record.title ?? record.id}</strong> from{' '}
              <strong>{collectionId}</strong>?
            </h4>

            <Button onClick={(e) => handleDelete(e)} color='error'>
              Confirm Deletion
            </Button>
            <Button onClick={(e) => handleCancel(e)}>Cancel</Button>
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};
export default ContentRecordDelete;
