import { ISessionTrackerConfig } from '@deloitte-us-consulting-dd/blueprint-beacon';
import { Grid, Typography } from '@mui/material';
import { AdminToolTip } from '../../../../../components/AdminToolTip';
import { FormInputText } from '../../form-components/FormInputText';
import { DefaultBeaconOptions } from '../TrackerConstants';

export const setSessionTrackerPrevConfig = (savedData, name) => {
  const sessionTrackerSettings: ISessionTrackerConfig = savedData;
  const form_data_obj: any = {
    ...DefaultBeaconOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };
  if (sessionTrackerSettings.settings) {
    form_data_obj.sessionDuration =
      sessionTrackerSettings.settings.sessionDuration;
  }
  return form_data_obj;
};

export const userSessionSetting = (form_data) => {
  if (form_data.sessionDuration === '') {
    return null;
  } else {
    return { sessionDuration: form_data.sessionDuration };
  }
};

export const setSessionOnSubmit = (form_data, updatedData) => {
  form_data.sessionDuration = updatedData.sessionDuration;
};

export const SessionTrackerForm = (control, setValue, onSubmit) => {
  return (
    <Grid container spacing={1}>
      <p>Session Duration (leave blank for default)</p>
      <AdminToolTip
        message={
          <Typography variant='caption'>
            {
              "The timeframe during which a user interacts with an experience. By default a session's duration is timed out after 30 minutes of inactivity"
            }
          </Typography>
        }
      ></AdminToolTip>
      <Grid item spacing={1} sm={12}>
        <FormInputText
          name='sessionDuration'
          data-testid={'session-radio'}
          control={control}
          label='ex: 180 (seconds)'
          setValue={setValue}
          submitfunction={onSubmit}
        />
      </Grid>
    </Grid>
  );
};
