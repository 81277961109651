import { useNavigate, useParams } from 'react-router-dom';

import { AdminContentCollectionEditor } from '../../../components/AdminContentCollectionEditor';
import AdminPage from '../../../components/AdminPage';

const ContentCollectionEdit = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const navigate = useNavigate();

  const onSuccess = (response) => {
    navigate(`/content/collections/${collectionId}`);
  };

  const onFailure = (data) => {
    console.error(data);
  };

  return (
    <>
      {collectionId && (
        <AdminPage showSidebar={true} title={'Edit Collection'}>
          <AdminContentCollectionEditor
            collectionId={collectionId}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </AdminPage>
      )}
    </>
  );
};

export default ContentCollectionEdit;
