import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminHorizontalFlow from '../../../components/AdminHorizontalFlow';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { AdminToastWarning } from '../../../components/AdminToasts';
import { defaultSelectedPIITraits } from '../../../config/traits';
import { uuidv4 } from '../../../utils/uuid';
import DestinationBlockedEvents from './DestinationBlockedEvents';
import DestinationDetails from './DestinationDetails';
import DestinationPII from './DestinationPII';
const DestinationsNew = () => {
  const { id } = useParams<{ id: string }>();
  const { config, adminActivityApi, adminDestinationApi } = useBlueprintAdmin();
  const [beacon, setBeacon] = useState<any>({});

  useEffect(() => {
    adminActivityApi!
      .getBeacon(id!)
      .then(({ data }) => setBeacon(data))
      .catch((e) => console.error(e));
  }, []);

  const navigate = useNavigate();
  const [cancelWarningTrigger, setCancelWarningTrigger]: any = useState(null);
  const validate = (values) => {
    const errors: any = {};
    if (values.platformId.length === 0) {
      errors.platformId = 'Please select a platform id';
    }
    switch (formik.values.platformId) {
      case 'FB':
        if (
          !(values.destinationId.length >= 15) ||
          !/^[0-9]+$/.test(values.destinationId)
        ) {
          errors.destinationId = 'Destination Id must be 15 digits';
        }
        if (values.accessToken.length === 0) {
          errors.accessToken = 'Please enter an access token';
        }
        if (values.destinationName.length === 0) {
          errors.destinationName = 'Please enter a destination name';
        }
        break;
      default:
        break;
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      platformId: '',
      destinationId: '',
      accessToken: '',
      blockedEvents: [],
      allowedPII: defaultSelectedPIITraits,
      destinationName: '',
    },
    onSubmit: (values) => {
      console.log('do nothing');
    },
    validate,
    validateOnChange: false,
  });

  const onCancelAny = () => {
    if (!cancelWarningTrigger) {
      setCancelWarningTrigger(true);
    } else {
      setCancelWarningTrigger(!cancelWarningTrigger);
    }
  };

  const handleSubmit = async () => {
    // push new destination values into already existing destinations array
    const newDestination = {
      id: uuidv4(),
      platformId: formik.values.platformId,
      destinationId: formik.values.destinationId,
      accessToken: formik.values.accessToken,
      blockedEvents: formik.values.blockedEvents,
      allowedPii: formik.values.allowedPII,
      beacon: beacon.id,
      name: formik.values.destinationName,
    };
    const response = await adminDestinationApi!.create(newDestination);
    if (response.success) {
      navigate(`/beacons/${beacon.id}`, {
        state: {
          lastPage: 'destinations',
        },
      });
      return true;
    } else {
      console.error('Error submitting destination data');
      return false;
    }
  };

  const flowConst = [
    {
      title: 'Destination Details',
      template: <DestinationDetails formik={formik} />,
      onSubmit: async () => {
        const errors = await formik.validateForm();
        return Object.keys(errors).length === 0;
      },
      onBackup: () => {
        return true;
      },
      onCancel: onCancelAny,
      hidden: false,
    },
    {
      title: 'Blocked Events',
      template: <DestinationBlockedEvents formik={formik} beacon={beacon} />,
      onSubmit: () => {
        return true;
      },
      onBackup: () => {
        return true;
      },
      onCancel: onCancelAny,
      hidden: false,
    },
    {
      title: 'Allowed PII',
      template: (
        <>
          <DestinationPII formik={formik} />
        </>
      ),
      onSubmit: handleSubmit,
      onBackup: () => {
        return true;
      },
      onCancel: onCancelAny,
      hidden: false,
    },
  ];

  return (
    <>
      {cancelWarningTrigger && (
        <AdminToastWarning
          message={'Are you sure you want to cancel?'}
          exitLocation={null}
          trigger={cancelWarningTrigger}
        ></AdminToastWarning>
      )}
      <AdminPage showSidebar={false} title='Create New Destination'>
        <Grid container>
          <Grid item xs={12}>
            <AdminHorizontalFlow
              flow={flowConst}
              submitButtonLabel='SET DESTINATION'
            ></AdminHorizontalFlow>
          </Grid>
        </Grid>
      </AdminPage>
    </>
  );
};

export default DestinationsNew;
