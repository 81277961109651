import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import QueryBuilder, { ValueEditorType } from 'react-querybuilder';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from '../../utils/uuid';
import AdminCard from '../AdminCard';
import { useBlueprintAdmin } from '../AdminProvider';
import { AdminToolTip } from '../AdminToolTip';
import {
  MaterialActionElement,
  MaterialNotToggle,
  MaterialValueEditor,
  MaterialValueSelector,
} from './MaterialConditions';

const useStyles = makeStyles((theme: Theme) => ({
  addGroup: {
    margin: 20,
    color: 'green',
    borderColor: 'green',
  },
  addRule: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  removeGroup: {
    color: 'red',
    borderColor: 'red',
  },
  removeRule: {
    marginTop: 20,
    color: 'red',
    borderColor: 'red',
  },
}));

const TriggerContentConditions = ({ trigger }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [query, setQuery] = useState(undefined);
  const { config, adminContentApi, adminTriggerApi } = useBlueprintAdmin();
  const [collectionValues, setCollectionValues] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchData = async () => {
      const collections = await adminContentApi!.getCollections();
      const options: Array<any> = [];

      for (const item of collections.data) {
        options.push({
          label: item.entity,
          name: item.entity,
          value: item.entity,
        });
      }
      setCollectionValues(options);
    };

    fetchData().catch((e) => {
      console.error(e);
    });
  }, []);

  const st: ValueEditorType = 'select';

  const fields = [
    {
      name: 'entity',
      label: 'Content Collection Entity',
      valueEditorType: st,
      values: collectionValues,
      defaultValue: trigger.match && trigger.match.entity,
    },
    {
      name: 'contentStatus',
      label: 'Content Status Change',
      valueEditorType: st,
      values: [
        {
          label: 'Publish',
          name: 'publish',
          value: 'publish',
        },
        {
          label: 'Unpublish',
          name: 'unpublish',
          value: 'unpublish',
        },
      ],
      value: trigger.match && trigger.match.contentStatus,
    },
    {
      name: 'action',
      label: 'Content Action',
      valueEditorType: st,
      values: [
        {
          label: 'Created',
          name: 'created',
          value: 'created',
        },
        {
          label: 'Updated',
          name: 'updated',
          value: 'updated',
        },
        {
          label: 'Deleted',
          name: 'deleted',
          value: 'deleted',
        },
      ],
      value: trigger.match && trigger.match.action,
    },
  ];

  useEffect(() => {
    setQuery(trigger.match);
  }, []);

  const submitQuery = async () => {
    const response = await adminTriggerApi!.updateTrigger(trigger.id, {
      match: query,
    });
    if (response.success) {
      navigate('./', {
        state: { detail: 'savedTrigger'.concat(uuidv4()) },
      });
    }
  };

  const handleOnQueryChange = (query) => {
    setQuery(query);
  };

  const saveAction = () => (
    <Button
      variant='outlined'
      color='primary'
      onClick={() => submitQuery()}
      className='btn btn-primary btn-sm float-right'
    >
      Save
    </Button>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <AdminCard
          title={
            <>
              Conditions
              <AdminToolTip
                message={
                  <Typography variant='caption'>
                    {
                      'Conditions are the rules that determine whether a trigger will perform a certain action. Conditions can be events, counts, and urls.'
                    }
                  </Typography>
                }
              ></AdminToolTip>
            </>
          }
          customAction={saveAction()}
        >
          <QueryBuilder
            fields={fields}
            showCombinatorsBetweenRules={true}
            controlClassnames={{
              addGroup: classes.addGroup,
              addRule: classes.addRule,
              removeGroup: classes.removeGroup,
              removeRule: classes.removeRule,
              // combinators: "form-control-m",
              // fields: "form-control form-control-m",
              // operators: "form-control-m",
              // value: "form-control-m",
            }}
            controlElements={{
              addGroupAction: MaterialActionElement,
              addRuleAction: MaterialActionElement,
              combinatorSelector: MaterialValueSelector,
              fieldSelector: MaterialValueSelector,
              notToggle: MaterialNotToggle,
              operatorSelector: MaterialValueSelector,
              removeGroupAction: MaterialActionElement,
              removeRuleAction: MaterialActionElement,
              valueEditor: MaterialValueEditor,
            }}
            query={query}
            onQueryChange={handleOnQueryChange}
          />
        </AdminCard>
      </Grid>
    </Grid>
  );
};

export default TriggerContentConditions;
