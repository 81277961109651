import { IFacebookTrackerConfig } from '@deloitte-us-consulting-dd/blueprint-beacon';
import { Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { FormInputRadio } from '../../form-components/FormInputRadio';
import { FormInputText } from '../../form-components/FormInputText';
import {
  beaconadvancedDropDown,
  DefaultBeaconOptions,
} from '../TrackerConstants';

export const setFbPixelPrevConfig = (savedData, name) => {
  const fbPixelTrackerSettings: IFacebookTrackerConfig = savedData;
  const form_data_obj: any = {
    ...DefaultBeaconOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };

  if (fbPixelTrackerSettings.settings) {
    form_data_obj.defaultSelected = 'advancedSettings';
    form_data_obj.fbPixelID = fbPixelTrackerSettings.settings.fbPixelID;
  }
  return form_data_obj;
};

export const userFBSettingFormChange = (form_data) => {
  if (form_data.defaultSelected != 'advancedSettings') {
    return null;
  } else {
    return { fbPixelID: form_data.fbPixelID };
  }
};

export const setFBPixelOnSubmit = (form_data, updatedData) => {
  form_data.defaultSelected = updatedData.advancedRadio;
  form_data.fbPixelID = updatedData.fbPixelID;
};

export const FBTrackerForm = (control, setValue, onSubmit, form_data) => {
  function RenderFollowup({ control }) {
    const FollowupSelector = useWatch({
      control,
      name: 'defaultSelected',
      defaultValue: 'defaultSettings',
    });

    if (FollowupSelector === 'advancedSettings') {
      return (
        <>
          <Grid item spacing={1} sm={12}>
            <FormInputText
              name='fbPixelID'
              control={control}
              label='Enter Facebook Pixel ID'
              setValue={setValue}
              defaultValue={form_data.fbPixelID}
              submitfunction={onSubmit}
            />
          </Grid>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item spacing={1} sm={12}>
        <FormInputRadio
          name={'defaultSelected'}
          control={control}
          label={''}
          setValue={setValue}
          optionsList={beaconadvancedDropDown}
          defaultValue={form_data.defaultSelected}
          submitfunction={onSubmit}
        />
      </Grid>
      <RenderFollowup control={control} />
    </Grid>
  );
};
