// CLIENT SPECIFIC SIDEBAR

// -----------------------------------------------------------------------
// 1. These items are fixed at the top of the sidebar
//   label: What we say on each sidebar item and the icon from icons.js
//   route: This provides a route to where to be routed to
// -----------------------------------------------------------------------
export const defaultFixed = [
  { label: 'Dashboard', route: '/', icon: 'HomeOutlined' },
  { label: 'Sign Out', route: '/auth/login', icon: 'ExitToAppOutlined' },
  { label: 'Content', route: '/content', icon: 'AssignmentOutlined' },
  // { label: 'invites', route: '/users/invites', icon: <DraftsOutlinedIcon fontSize="large" /> },
];

// -----------------------------------------------------------------------
// 2. These items are mapped against the roles to conditionally show
//   label: What we say on each sidebar item and the icon from icons.js
//   role: What role to validate on (e.g. role of "asset" is "asset:admin:*")
// -----------------------------------------------------------------------
export const roleBased = [
  {
    label: 'Beacons',
    role: 'activity',
    route: '/beacons',
    icon: 'TrackChangesOutlined',
  },
  {
    label: 'Assets',
    role: 'asset',
    route: '/assets',
    icon: 'PermMediaOutlined',
  },
  {
    label: 'Users',
    role: 'user',
    route: '/users',
    icon: 'PersonOutlineOutlined',
  },
  {
    label: 'Groups',
    role: 'userGroup',
    route: '/users/group',
    icon: 'GroupOutlined',
  },
  {
    label: 'Identity',
    role: 'user',
    route: '/identity',
    icon: 'AssignmentIndOutlined',
  },
  {
    label: 'Prospects',
    role: 'user',
    route: '/prospects',
    icon: 'ContactMailOutlined',
  },
  {
    label: 'Segments',
    role: 'segment',
    route: '/segments',
    icon: 'GroupOutlined',
  },
  {
    label: 'Triggers',
    role: 'trigger',
    route: '/triggers',
    icon: 'ImportExportOutlined',
  },
  {
    label: 'Notifications',
    role: 'notification',
    route: '/notifications',
    icon: 'NotificationsActiveOutlined',
  },
  {
    label: 'Jobs',
    role: 'schedule',
    route: '/jobs',
    icon: 'AccessAlarmOutlined',
  },
  // { label: 'Activity', role: 'activity', route: '/activities', icon: "TimelineOutlined" },
  {
    label: 'Admins',
    role: 'admin',
    route: '/admins',
    icon: 'SupervisedUserCircleOutlined',
  },
  {
    label: 'Config',
    role: 'config',
    route: '/config',
    icon: 'SettingsOutlined',
  },
];

export const defaultRoles = [
  'Beacons',
  'Assets',
  'Users',
  'Groups',
  'Identity',
  'Segments',
  // "Activity",
  'Triggers',
  'Notifications',
  'Jobs',
  'Admins',
  'Config',
];
