import {
  AdminActivityAPI,
  AdminDestinationAPI,
} from '@deloitte-us-consulting-dd/blueprint-sdk-activity';
import {
  AdminAPI,
  AdminDashboardAPI,
  AdminGroupAPI,
} from '@deloitte-us-consulting-dd/blueprint-sdk-admin';
import { AdminAssetAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-asset';
import { AdminContentAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-content';
import { AdminTriggerAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-trigger';
import { AdminUserAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-user';

import * as React from 'react';
import { useState } from 'react';
// Hooks
import {
  AdminScopedAPI,
  LocalStorage,
} from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import OktaAuth from '@okta/okta-auth-js';
import useMountedEffect from '../hooks/useMountedEffect';
import Logger from '../utils/logger';

export interface BlueprintConfigType {
  adminApi: AdminAPI | undefined;
  adminGroupApi: AdminGroupAPI | undefined;
  adminUserApi: AdminUserAPI | undefined;
  adminAssetApi: AdminAssetAPI | undefined;
  adminDashboardApi: AdminDashboardAPI | undefined;
  adminContentApi: AdminContentAPI | undefined;
  adminTriggerApi: AdminTriggerAPI | undefined;
  adminActivityApi: AdminActivityAPI | undefined;
  adminDestinationApi: AdminDestinationAPI | undefined;
  mounted: boolean;
  adminUser: any;
  jwtToken: string | undefined;
  isAuthenticated: boolean;
  config: IConfig;
  isFirstAdmin: boolean;
  loadAdminProvider: any;
  logout: any;
}

export interface IConfig {
  apiRoot: string;
  debug: boolean;
  startPage: string;
  sidebar: { fixed: any; roleBased: any; roleBasedOptions: any };
  reportingKey: string;
  authMethod: {
    type: 'provider' | 'password' | 'azure' | 'okta';
  };
  oktaLoginInstance: OktaAuth;
  providerToken: string | undefined;
}

export const decodeJwt = (token) => JSON.parse(atob(token.split('.')[1]));

export function createCtx<ContextType>() {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) {
      throw new Error('useCtx must be inside a Provider with a value');
    }
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

// Create UserContext we can consume downstream
const [useBlueprintAdmin, Provider] = createCtx<BlueprintConfigType>();

function AdminProvider({ config, children }: any) {
  // If config is null, default to generic
  const adminConfig: IConfig = config ?? {
    apiRoot: 'http://v2.blueprint.ddapps.xyz',
    debug: true,
    reportingKey: '',
    sidebar: {},
    startPage: '/',
    authMethod: {
      type: 'password',
    },
    providerToken: undefined,
    oktaLoginInstance: undefined,
  };

  function revalidateToken(config: IConfig) {
    if (config.authMethod.type === 'password') {
      setIsAuthenticated(false);
      // window.location.href = '/auth/login';
      logger.info('redirected to /auth/login token expired');
    }
  }

  const logger = new Logger({
    name: 'AdminProvider',
    isEnabled: adminConfig.debug,
  });

  const [adminApi, setAdminApi] = useState<AdminAPI>();
  const [adminGroupApi, setAdminGroupApi] = useState<AdminGroupAPI>();
  const [adminUserApi, setAdminUserApi] = useState<AdminUserAPI>();
  const [adminAssetApi, setAdminAssetApi] = useState<AdminAssetAPI>();
  const [adminDashboardApi, setAdminDashboardApi] =
    useState<AdminDashboardAPI>();
  const [adminContentApi, setAdminContentApi] = useState<AdminContentAPI>();
  const [adminTriggerApi, setAdminTriggerApi] = useState<AdminTriggerAPI>();
  const [adminActivityApi, setAdminActivityApi] = useState<AdminActivityAPI>();
  const [adminDestinationApi, setAdminDestinationApi] =
    useState<AdminDestinationAPI>();
  const [jwtToken, setJwtToken] = useState<string | undefined>();
  const [adminUser, setAdminUser] = useState<string | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [isFirstAdmin, setIsFirstAdmin] = useState<boolean>(false);

  const handleProviderToken = () => {
    // If the admin is set to use okta, then set the provider token
    if (
      adminConfig.authMethod.type === 'okta' &&
      adminConfig.oktaLoginInstance
    ) {
      config.providerToken = adminConfig.oktaLoginInstance.getIdToken();
    }
  };

  const logout = () => {
    new LocalStorage().clear();
    window.location.href = '/auth/login';
  };

  const loadAdminProvider = async () => {
    logger.important(`Blueprint Debug`);
    logger.debug(`config`, adminConfig);

    const accessToken = localStorage.getItem(`bp-admin`);

    if (accessToken) {
      const decodedToken = decodeJwt(accessToken);
      const expiry = decodedToken.exp;
      const now = Math.floor(Date.now() / 1000);
      const isExpired = expiry < now;

      // If the token isn't expired, then let's proceed
      if (!isExpired) {
        logger.debug(`Authenticated - accessToken is set and not expired`);
        setIsAuthenticated(true);
        setJwtToken(accessToken);

        // We might need a provider token to add to our config calls
        handleProviderToken();

        // Create our APIs now that we have a valid accessToken
        setAdminApi(new AdminAPI(config, accessToken));
        setAdminGroupApi(new AdminGroupAPI(config, accessToken));
        setAdminUserApi(new AdminUserAPI(config, accessToken));
        setAdminAssetApi(new AdminAssetAPI(config, accessToken));
        setAdminDashboardApi(new AdminDashboardAPI(config, accessToken));
        setAdminContentApi(new AdminContentAPI(config, accessToken));
        setAdminTriggerApi(new AdminTriggerAPI(config, accessToken));
        setAdminActivityApi(new AdminActivityAPI(config, accessToken));
        setAdminDestinationApi(new AdminDestinationAPI(config, accessToken));

        logger.debug(config);

        // Set the CubeJS reportingKey to be the same as the accessToken
        adminConfig.reportingKey = accessToken;

        setAdminUser(decodedToken);
      } else {
        revalidateToken(adminConfig);
      }
    } else {
      setAdminApi(
        new AdminAPI({
          apiRoot: adminConfig.apiRoot,
          debug: adminConfig.debug,
        })
      );

      // handle first time admin setup
      const api = new AdminScopedAPI(adminConfig, 'AdminProvider');
      const firstAdmin = await api.get({
        path: '/admin/admins/status',
      });
      if (firstAdmin.success) {
        if (
          !firstAdmin.data.isSetup &&
          adminConfig.authMethod.type === 'password'
        ) {
          setIsFirstAdmin(!firstAdmin.data.isSetup);
          // window.location.href = '/setup';
        } else {
          // This isnt the first user, so let's push back to have them login again
          // navigate('/auth/login');
        }
      } else {
        // window.location.href = '/auth/login';
      }
    }
  };

  useMountedEffect((mounted: boolean) => {
    // eslint-disable-next-line
    (async () => {
      if (mounted) {
        await loadAdminProvider();
        // Let's set is mounted so that it doesnt reload
        setMounted(true);
      }
    })();
    // @TODO need to add support for anonymous users to be seeded as unknowns

    // eslint-disable-next-line
    return () => (mounted = false);
  }, []);

  return (
    <Provider
      value={{
        adminApi,
        adminGroupApi,
        adminUserApi,
        adminAssetApi,
        adminDashboardApi,
        adminContentApi,
        adminTriggerApi,
        adminActivityApi,
        adminDestinationApi,
        mounted,
        adminUser,
        jwtToken,
        isAuthenticated,
        isFirstAdmin,
        config: adminConfig,
        loadAdminProvider,
        logout,
      }}
    >
      {children}
    </Provider>
  );
}
export { AdminProvider, useBlueprintAdmin };
