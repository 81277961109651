import { IUserTrackerConfig } from '@deloitte-us-consulting-dd/blueprint-beacon';
import { Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { FormInputDropdown } from '../../form-components/FormInputDropdown';
import { FormInputRadio } from '../../form-components/FormInputRadio';
import { FormInputText } from '../../form-components/FormInputText';
import {
  DefaultBeaconOptions,
  userBeaconAnonCookieDD,
  userBeaconAssumeIdentityDDOptions,
  userBeaconCheckboxOptions,
} from '../TrackerConstants';

export const setUserTrackerPrevConfig = (savedData, name) => {
  const userTrackerSettings: IUserTrackerConfig = savedData;
  const form_data_obj: any = {
    ...DefaultBeaconOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };

  if (userTrackerSettings.settings) {
    if (userTrackerSettings.settings.autoTrack === false) {
      form_data_obj.UserTrackerSetting = 'optIn';
    } else if (userTrackerSettings.settings?.assumeIdentity) {
      form_data_obj.UserTrackerSetting = 'AssumeIdentity';
      form_data_obj.AssumeIdentityKey =
        userTrackerSettings.settings.assumeIdentity.key;
      form_data_obj.AssumeIdentityType =
        userTrackerSettings.settings.assumeIdentity.type;
    } else if (userTrackerSettings.settings?.seedAnonymousUsers) {
      form_data_obj.UserTrackerSetting = 'seedAnonymousCookieless';
    } else if (userTrackerSettings.settings?.seedAnonymousWithCookie) {
      form_data_obj.UserTrackerSetting = 'seedAnonymousCookie';
      form_data_obj.SAUtextValue =
        userTrackerSettings.settings.seedAnonymousWithCookie.key;
    }
  }
  return form_data_obj;
};

export const userDataSettingFormChange = (form_data) => {
  if (form_data.UserTrackerSetting === 'optIn') {
    return { autoTrack: false };
  } else if (form_data.UserTrackerSetting === 'AssumeIdentity') {
    return {
      assumeIdentity: {
        type: form_data.AssumeIdentityType,
        key: form_data.AssumeIdentityKey,
      },
    };
  } else if (form_data.UserTrackerSetting === 'seedAnonymousCookieless') {
    return { seedAnonymousUsers: true };
  } else if (form_data.UserTrackerSetting === 'seedAnonymousCookie') {
    return { seedAnonymousWithCookie: { key: form_data.SAUtextValue } };
  } else {
    return null;
  }
};

export const setUserOnSubmit = (form_data, updatedData) => {
  form_data.UserTrackerSetting = updatedData.UserTrackerSetting;
  form_data.AssumeIdentityKey = updatedData.AssumeIdentityKey;
  form_data.AssumeIdentityType = updatedData.AssumeIdentityType;
  form_data.SAUtextValue = updatedData.SAUtextValue;
};

export const UserTrackerForm = (control, setValue, onSubmit, form_data) => {
  const AssumeIdentityDD = userBeaconAssumeIdentityDDOptions;
  const CookieDD = userBeaconAnonCookieDD;
  const checkboxOptions = userBeaconCheckboxOptions;
  function RenderFollowup({ control }) {
    const FollowupSelector = useWatch({
      control,
      name: 'UserTrackerSetting',
      defaultValue: 'default',
    });

    switch (FollowupSelector) {
      case 'AssumeIdentity':
        return (
          <>
            <Grid item spacing={1} sm={12}>
              <FormInputDropdown
                name='AssumeIdentityType'
                setValue={setValue}
                control={control}
                label='type'
                optionsList={AssumeIdentityDD}
                defaultValue={form_data.AssumeIdentityType}
                submitfunction={onSubmit}
              />
            </Grid>
            <Grid item spacing={1} sm={12}>
              <FormInputText
                name='AssumeIdentityKey'
                control={control}
                label='key to use (e.g. the cookie name)'
                defaultValue={form_data.AssumeIdentityKey}
                setValue={setValue}
                submitfunction={onSubmit}
              />
            </Grid>
          </>
        );
      case 'seedAnonymousCookie':
        return (
          <>
            <Grid item spacing={1} sm={12}>
              <FormInputText
                name='SAUtextValue'
                control={control}
                label='key to use (The cookie name)'
                defaultValue={form_data.SAUtextValue}
                setValue={setValue}
                submitfunction={onSubmit}
              />
            </Grid>
          </>
        );
      default:
        return <></>;
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <FormInputRadio
          name={'UserTrackerSetting'}
          control={control}
          label={''}
          setValue={setValue}
          optionsList={checkboxOptions}
          defaultValue={form_data.UserTrackerSetting}
          submitfunction={onSubmit}
        />
      </Grid>
      <RenderFollowup control={control} />
    </Grid>
  );
};
