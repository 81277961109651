import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableRow,
  Typography,
  Theme,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSVDownloader } from 'react-papaparse';
import AdminCard from './AdminCard';
import { AdminTableHead, AdminTableHeadCell } from './AdminTable';
import { makeStyles } from '@mui/styles';

/**
 *
 * @param key object key to get average from
 * @param items array of objects
 * @returns if the average is NaN we just return back the key, otherwise return the average
 */
const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    marginTop: theme.spacing(1),
  },
}));

export const getAverage = (key: string, items: any[]) => {
  if (items) {
    const avg =
      items?.reduce(
        (totalNum: number, next: any) => totalNum + parseFloat(next[key]),
        0
      ) / items.length;
    return Number.isNaN(avg) ? key : avg.toFixed(2);
  } else {
    return 0;
  }
};

interface Props {
  title?: string;
  headings: any[];
  data: any;
  onInfiniteLoad?: () => void;
  isLoading: boolean;
  emptyTemplate?: any;
  itemTemplate: any;
  search?: any;
  newAction?: any;
  exportEnabled?: boolean;
  showCard?: boolean;
  loader?: JSX.Element;
  customRows?: JSX.Element[];
  setSearchTerm?: any;
}

function AdminPaginatedListV2({
  title,
  isLoading,
  data,
  emptyTemplate,
  itemTemplate,
  headings,
  onInfiniteLoad = () => {
    return data;
  },
  search,
  newAction,
  exportEnabled = false,
  showCard = true,
  loader = <></>,
  customRows,
  setSearchTerm,
}: Props) {
  const classes = useStyles();
  const showExportIfEnabled = () => {
    if (exportEnabled) {
      const newContent = data.map((obj) => {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          newObj[key] = JSON.stringify(value);
        });
        return newObj;
      });
      return (
        <CSVDownloader
          data={newContent}
          filename={title}
          bom={true}
          type={'link'}
        >
          <Button variant='outlined' color='primary'>
            Download
          </Button>
        </CSVDownloader>
      );
    } else {
      return null;
    }
  };

  const memoizedShowExport = useMemo(
    () => showExportIfEnabled(),
    [exportEnabled]
  );

  const handleSubmit = (values) => {
    setSearchTerm(values.search);
  };

  return (
    <>
      <Grid>
        {headings && (
          <AdminCard
            showCard={showCard}
            title={title}
            customAction={memoizedShowExport}
            newAction={newAction}
          >
            <InfiniteScroll
              dataLength={data.length}
              next={onInfiniteLoad}
              hasMore={true}
              loader={loader}
            >
              {search && (
                <Formik
                  initialValues={{
                    search: '',
                  }}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <FormGroup>
                        <FormControl
                          className={clsx(classes.searchBox)}
                          variant='outlined'
                        >
                          <InputLabel htmlFor='outlined-adornment-password'>
                            Search
                          </InputLabel>
                          <OutlinedInput
                            id='search'
                            name='search'
                            label='Search'
                            placeholder='Enter search terms'
                            value={props.values.search}
                            onChange={props.handleChange}
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  name='search'
                                  onClick={() => handleSubmit(props.values)}
                                  onMouseDown={() => handleSubmit(props.values)}
                                  edge='end'
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              )}
              {isLoading && (
                <Skeleton
                  variant='rectangular'
                  height={250}
                  style={{ marginTop: 10 }}
                />
              )}

              {data && !isLoading && (
                <>
                  <Table style={{ marginTop: 10 }}>
                    <AdminTableHead>
                      <TableRow>
                        {headings?.map((heading) => (
                          <React.Fragment key={heading}>
                            <AdminTableHeadCell>{heading}</AdminTableHeadCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </AdminTableHead>
                    <TableBody>
                      {customRows && customRows}
                      {data?.map((item: any, index) =>
                        itemTemplate(item, index)
                      )}
                    </TableBody>
                  </Table>
                </>
              )}

              {data && !isLoading && data.length === 0 && (
                <Box style={{ backgroundColor: '#fafafa', padding: 20 }}>
                  {emptyTemplate && (
                    <Typography variant='caption'>{emptyTemplate()}</Typography>
                  )}
                  {!emptyTemplate && (
                    <Typography variant='caption'>No results</Typography>
                  )}
                </Box>
              )}
            </InfiniteScroll>
          </AdminCard>
        )}
      </Grid>
    </>
  );
}

export default AdminPaginatedListV2;
