import { Button, TableCell, TableRow, Link as UiLink } from '@mui/material';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminTabs from '../../components/AdminTabs';

const ProspectList = () => {
  const { adminUserApi } = useBlueprintAdmin();

  const itemTemplate = (
    { id, external_id, external_source, updatedAt, record, userId },
    index
  ) => (
    <TableRow key={index}>
      <TableCell>
        <UiLink component={Link} to={`/prospects/${id}`}>
          {external_id}
        </UiLink>
      </TableCell>
      <TableCell>{external_source}</TableCell>
      <TableCell>{<Moment fromNow>{updatedAt}</Moment>}</TableCell>
      <TableCell>
        {userId != 'false' && (
          <UiLink component={Link} to={`/users/${userId}`}>
            {userId}
          </UiLink>
        )}
        {userId === 'false' && 'no match'}
      </TableCell>
      <TableCell>
        {userId === 'false' && (
          <Link to={`/prospects/match/${id}`}>
            <Button variant='contained' color='primary'>
              Match
            </Button>
          </Link>
        )}
      </TableCell>
    </TableRow>
  );

  const syncAll = () => (
    <Button
      variant='contained'
      color='primary'
      onClick={async () => await adminUserApi!.syncAllProspects()}
    >
      Sync All
    </Button>
  );

  const tabs: Array<{
    title: string;
    template: React.ReactNode;
    icon?: React.ReactNode;
  }> = [
    {
      title: 'All',
      template: (
        <AdminCard title='All Prospects' customAction={syncAll()}>
          <AdminPaginatedList
            columns={['id', 'source', 'updated', 'user matched?', '']}
            url='/admin/prospects'
            itemTemplate={itemTemplate}
          />
        </AdminCard>
      ),
    },
    {
      title: 'Unmatched Only',
      template: (
        <AdminCard title='Unmatched Prospects'>
          <AdminPaginatedList
            columns={['id', 'source', 'updated', 'user', '']}
            url='/admin/prospects'
            additionalQueries='&unMatchedOnly=true'
            itemTemplate={itemTemplate}
          />
        </AdminCard>
      ),
    },
  ];

  return (
    <AdminPage animation={true} title='Prospects' newAction='/prospects/new'>
      <AdminTabs tabs={tabs} />
    </AdminPage>
  );
};

export default ProspectList;
