import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const ContentCollectionDelete = () => {
  const { adminContentApi } = useBlueprintAdmin();
  const navigate = useNavigate();
  const { collectionId } = useParams<{ collectionId: string }>();
  const [collection, setCollection] = useState<any>();

  const handleDelete = async (e) => {
    // SEND TO DELETE
    e.stopPropagation();
    e.preventDefault();
    await adminContentApi
      ?.deleteCollection(collectionId!)
      .then(() => {
        navigate(`/content`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/content`);
  };

  useEffect(() => {
    if (collectionId && adminContentApi) {
      adminContentApi
        .getCollection(collectionId)
        .then(({ data }) => {
          setCollection(data);
        })
        .catch((err) => console.error(err));
    }
  }, [collectionId]);

  return (
    <>
      {collectionId && collection && (
        <AdminPage title='Delete Collection'>
          <AdminCard title=''>
            <h4>
              Are your sure you want to delete{' '}
              <strong>{collection.name}</strong>?
            </h4>

            <Button onClick={(e) => handleDelete(e)} color='error'>
              Confirm Deletion
            </Button>
            <Button onClick={(e) => handleCancel(e)}>Cancel</Button>
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};
export default ContentCollectionDelete;
