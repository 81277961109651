import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { AdminToolTip } from '../../components/AdminToolTip';

const AssetCreate = () => {
  const { adminAssetApi } = useBlueprintAdmin();

  const onSubmitTrigger = async (values) => {
    // const data: ITriggersCreate = {
    //   name: values.name,
    //   type: values.type,
    //   beaconId: values.beacon ? JSON.parse(values.triggerBeacon).key : null,
    // };
    // console.log(JSON.stringify(data));
    // const response = await adminTriggerApi!.create(data);
    // if (response.success) {
    //   navigate({
    //     pathname: `/triggers/${response.data.id}`,
    //     state: { detail: 'newTrigger' },
    //   });
    //   return true;
    // } else {
    //   console.error(response.message ?? 'error creating trigger');
    //   return false;
    // }
  };

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Please enter a name';
    }

    return errors;
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validate: validate,
    initialValues: {
      name: '',
      type: '',
      beacon: '',
    },
    onSubmit: async (values) => {
      await onSubmitTrigger(values);
    },
  });

  return (
    <>
      <AdminPage showSidebar={true} title='Create New Asset'>
        <Grid>
          <AdminCard title='Trigger Details'>
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                margin='dense'
                fullWidth
                style={{ paddingBottom: 20 }}
              >
                <Grid container style={{ overflow: 'visible' }}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      Asset name
                      <AdminToolTip
                        message={
                          <Typography variant='caption'>
                            What is the name of the asset?
                          </Typography>
                        }
                      ></AdminToolTip>
                    </Typography>
                    <Button component='label'>
                      Select File
                      <input
                        name='file'
                        id='contained-button-file'
                        type='file'
                        hidden
                        onChange={async (e: any) => {
                          if (e?.target?.files) {
                            const file = e.target.files[0];

                            await adminAssetApi!.uploadAsset({
                              file: file,
                            });
                          }
                        }}
                      />
                    </Button>
                    <TextField
                      error={formik.errors.name !== undefined}
                      helperText={formik.errors.name}
                      defaultValue={formik.values.name}
                      value={formik.values.name}
                      id='name'
                      name='name'
                      type='text'
                      variant='outlined'
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <Button
                variant='contained'
                size='large'
                id='submit'
                type='submit'
              >
                Submit
              </Button>
            </form>
          </AdminCard>
        </Grid>
      </AdminPage>
    </>
  );
};

export default AssetCreate;
