import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { uiSchema } from '../../forms/admins';

const AdminNew = () => {
  const { config, adminGroupApi } = useBlueprintAdmin();
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);

  const onSuccess = (response) => {
    navigate(`/admins/${response.data.id}`, {
      state: { detail: 'newAdmin' },
    });
  };

  useEffect(() => {
    adminGroupApi!
      .getGroups()
      .then(({ data }) => {
        const response = data.map((group) => ({
          type: 'string',
          enum: group,
          enumNames: group.name,
          title: group.name,
        }));
        setGroups(response);
      })
      .catch((e) => console.error(e));
  }, []);

  function validate(formData, errors) {
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword.addError("Passwords don't match");
    }
    return errors;
  }

  const schema = {
    description: 'Creating an admin',
    type: 'object',
    properties: {
      username: {
        type: 'string',
        title: 'Username',
        description: 'The username (email) used for login',
      },
      password: {
        type: 'string',
        title: 'Password',
        description: 'The password used for login',
      },
      confirmPassword: {
        type: 'string',
        title: 'Confirm Password',
        description: 'Confirm the password used for login',
      },
      groups: {
        type: 'array',
        title: 'Groups',
        items: {
          title: 'Groups',
          type: 'string',
          enum: groups.map((group: any) => group.enum.id),
          enumNames: groups.map((group: any) => group.enumNames),
        },
        description: 'Groups to be applied to this administrator account',
      },
    },
  };

  return (
    <AdminBaseForm
      url='/admin/admins'
      entityTitle='Admin'
      schema={schema}
      validate={validate}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default AdminNew;
