import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import { getUserApiRoot } from '../../utils/shared';
import { uuidv4 } from '../../utils/uuid';

const useStyles = makeStyles({
  AccordianOuterBox: {
    width: '100%',
    boxShadow: 'none',
  },
  AccordionSummaryHelp: {
    width: '100px',
  },
  AccordionSummaryAdvanced: {
    width: '205px',
  },
  AccordionExtensionyAdvanced: {
    width: '270px',
  },
  AccordionCardClass: {
    borderRadius: '5px',
    border: '1px solid #e3e3e3',
    width: '100%',
  },
  AccordionBoarderlessCardClass: {
    borderRadius: '0px',
    border: '0px solid #e3e3e3',
    width: '100%',
  },
});

export const InstallBeacon = ({
  beacon,
  isDevelopment,
  setSelectedDetailTab,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const renderTrackers = () => {
    const returnValue = beacon.trackers.map((t) => t);

    return returnValue;
  };

  useEffect(() => {
    if (setSelectedDetailTab) {
      setSelectedDetailTab('InstallBeacon');
    }
  }, []);

  const renderConfigRoot = () => ({
    log: beacon.debug,
    send: true, // We should always send!
    apiRoot: getUserApiRoot(beacon.apiRoot),
    beacon: beacon.key,
    trackers: renderTrackers(),
  });

  const renderBeaconScript = (type) => {
    if (beacon) {
      const config = renderConfigRoot();
      let code = '';

      if (type === 'inline') {
        code = `<script type='text/javascript'>
                !function(e,t){if(e.blueprint=e.blueprint||[],!e._blueprint){e._blueprint={loaded:!1,queue:e.blueprint};var n=e.blueprint=function(t,n,o,p){e._blueprint.loaded?e._blueprint.call(t,n,o,p):e._blueprint.queue.push([t,n,o,p])};e.blueprint.push=n;var r=t.createElement("script");r.type="text/javascript",r.async=!0,r.src="${getUserApiRoot(
                  beacon.apiRoot
                )}/static/blueprint.js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(r,p),blueprint("init",${JSON.stringify(
          config,
          null,
          0
        )})}}(window,document);
                </script>`;
      } else if (type === 'dynamic') {
        code = `<script type='text/javascript'>
                  !function(e,t){if(e.blueprint=e.blueprint||[],!e._blueprint){e._blueprint={loaded:!1,queue:e.blueprint};var n=e.blueprint=function(t,n){e._blueprint.loaded?e._blueprint.call(t,n,o,p):e._blueprint.queue.push([t,n,o,p])};e.blueprint.push=n;var r=t.createElement("script");r.type="text/javascript",r.async=!0,r.src="${
                    beacon.apiRoot
                  }/static/blueprint.js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(r,p)}}(window,document);
                  blueprint("load",["${getUserApiRoot(beacon.apiRoot)}","${
          beacon.key
        }"])
                </script>`;
      }

      return code
        .replace(/[\r\n]/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ >/g, '>')
        .replace(/> </g, '><')
        .trim();
    }
  };

  const RenderCompiledBeacon = () => (
    <AdminCard title='Compiled Beacon'>
      <Typography variant='subtitle2'>
        This beacon is best for production deployments, where the configuration
        is included in the tracking tag itself. This makes it faster, however
        you cannot change any configurations without re-installing this snippet.
      </Typography>
      <div
        contentEditable='false'
        suppressContentEditableWarning={true}
        data-testid='beacon-script'
        style={{
          height: 'auto',
          width: '100%',
          padding: 10,
          marginTop: 10,
          borderColor: '#ccc',
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundColor: '#f0ecec',
        }}
        onClick={() => {
          navigate('./', {
            state: { detail: 'CopiedToClipBoard'.concat(uuidv4()) },
          });
          void navigator.clipboard.writeText(
            renderBeaconScript('inline') || 'noBeacon'
          );
        }}
      >
        {renderBeaconScript('inline')}
      </div>
      <Typography variant='caption'>
        Copy this code into the bottom of you body tag in your html
      </Typography>
    </AdminCard>
  );

  const RenderExtensionAccordian = () => (
    <Accordion className={classes.AccordianOuterBox}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={classes.AccordionExtensionyAdvanced}
      >
        <Typography>Blueprint Chrome Extension</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionCardClass}>
        <Typography variant='subtitle2'>
          Use this installation method if you are doing early client assessment
          and validation. This method will allow you to emulate what a client
          installation will look like, by loading the blueprint beacon on a
          client domain, effectively "mocking" an installation.
          <ol>
            <li>Start by installing the Blueprint Chrome Extension</li>
            <li>
              Navigate to the page of the client that you want to do a mock
              installation on
            </li>
            <li>Click the Blueprint icon in the toolbar</li>
            <li>
              <span>
                Place this value in the <strong>API Root</strong> field
              </span>
              <div
                contentEditable='true'
                suppressContentEditableWarning={true}
                style={{
                  height: 'auto',
                  width: '100%',
                  padding: 10,
                  marginTop: 10,
                  borderColor: '#ccc',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  backgroundColor: '#f0ecec',
                }}
              >
                {getUserApiRoot(beacon.apiRoot)}
              </div>
            </li>
            <li style={{ marginTop: 10 }}>
              <span>
                Place this value in the <strong>Beacon Key</strong> field
              </span>
              <div
                contentEditable='true'
                suppressContentEditableWarning={true}
                style={{
                  height: 'auto',
                  width: '100%',
                  padding: 10,
                  marginTop: 10,
                  borderColor: '#ccc',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  backgroundColor: '#f0ecec',
                }}
              >
                {beacon.key}
              </div>
            </li>
            <li style={{ marginTop: 10 }}>
              Blueprint should be setup on the client domain for you to debug
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const RenderDynamicBeacon = () => (
    <AdminCard title='Dynamic Beacon'>
      <Typography variant='subtitle2'>
        This beacon is best for early deployments where you can adjust the
        configuration after installation. When the beacon is loaded, it will
        call the API to get the configuration. This keeps it flexible without
        constant deployments but adds an additional network call.
      </Typography>
      <div
        contentEditable='false'
        suppressContentEditableWarning={true}
        style={{
          height: 'auto',
          width: '100%',
          padding: 10,
          marginTop: 10,
          borderColor: '#ccc',
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundColor: '#f0ecec',
        }}
        onClick={() => {
          navigate('./', {
            state: { detail: 'CopiedToClipBoard'.concat(uuidv4()) },
          });
          void navigator.clipboard.writeText(
            renderBeaconScript('dynamic') || 'noBeacon'
          );
        }}
      >
        {renderBeaconScript('dynamic')}
      </div>
      <Typography variant='caption'>
        Copy this code into the bottom of you body tag in your html
      </Typography>
    </AdminCard>
  );

  const HelpAccordian = () => (
    <Accordion className={classes.AccordianOuterBox}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={classes.AccordionSummaryHelp}
      >
        <Typography>Help</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionCardClass}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h6'>Beacon Installation Help</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Review the following documentation to install the beacon.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Link 1 to documentation</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Link 2 to documentation</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Link 3 to documentation</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  const AdvancedAccordian = () => (
    <Accordion className={classes.AccordianOuterBox}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={classes.AccordionSummaryAdvanced}
      >
        <Typography>Advanced Settings</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionBoarderlessCardClass}>
        <RenderCompiledBeacon></RenderCompiledBeacon>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Grid container spacing={4} style={{ marginTop: 5 }}>
      {isDevelopment && (
        <>
          <Grid item style={{ paddingBottom: '0px' }}>
            <RenderDynamicBeacon></RenderDynamicBeacon>
            <RenderExtensionAccordian></RenderExtensionAccordian>
          </Grid>
        </>
      )}
      {!isDevelopment && (
        <Grid item>
          <RenderCompiledBeacon></RenderCompiledBeacon>
          <HelpAccordian></HelpAccordian>
        </Grid>
      )}
      <Grid item xs={12} style={{ paddingTop: '0px' }}>
        {isDevelopment && <AdvancedAccordian></AdvancedAccordian>}
      </Grid>
    </Grid>
  );
};
