import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { TableCell, Link as UiLink } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';
import AdminPage from '../../../components/AdminPage';
import AdminPaginatedList from '../../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { AdminTableRow } from '../../../components/AdminTable';

const fetchActiveGroups =
  (api: AdminScopedAPI, searchTerm: string) =>
  ({ pageParam = 1 }) => {
    const paginatedUrl = `/admin/user-groups?limit=20&page=${pageParam}`;

    return api
      .get({ path: paginatedUrl, body: { query: searchTerm } })
      .then((data) => {
        if (data.data) {
          return data.data;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

const fetchPendingGroups =
  (api: AdminScopedAPI) =>
  ({ pageParam = 1 }) => {
    const paginatedUrl = `/admin/user-groups/pending?limit=20&page=${pageParam}`;

    return api
      .get({ path: paginatedUrl })
      .then((data) => {
        if (data.data) {
          return data.data;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

const UserGroupList = () => {
  const [activeContent, setActiveContent] = useState<Array<any>>([]); // active groups
  const [pendingContent, setPendingContent] = useState<Array<any>>([]); // pending groups
  const [search, setSearch] = useState<string>('');
  const { config } = useBlueprintAdmin();
  const api = new AdminScopedAPI(config, 'AdminPaginatedList');

  const activeGroups = useInfiniteQuery(
    'user-groups',
    fetchActiveGroups(api, search),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  const pendingGroups = useInfiniteQuery(
    'user-groups-pending',
    fetchPendingGroups(api),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    /**
     * when user submits search query refetch pagination with the latest filter
     */
    activeGroups
      .refetch()
      .then((response) => {
        if (response && response.data) {
          setActiveContent(response.data.pages.flat());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [search]);

  useEffect(() => {
    console.log(activeGroups);
    if (activeGroups.data && activeGroups.data.pages.length > 0) {
      setActiveContent(activeGroups.data.pages.flat());
    }
  }, [activeGroups.data?.pages.length]);

  useEffect(() => {
    console.log(pendingGroups);
    if (pendingGroups.data && pendingGroups.data.pages.length > 0) {
      setPendingContent(pendingGroups.data.pages.flat());
    }
  }, [pendingGroups.data?.pages.length]);

  const itemTemplate = ({ id, name, description }, index) => (
    <AdminTableRow key={index}>
      <TableCell>
        <UiLink component={Link} to={`/users/group/${id}`}>
          {name}
        </UiLink>
      </TableCell>
      <TableCell>{description}</TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage
      animation={true}
      title='User Groups'
      newAction='/users/group/new'
      customActionText='Actions'
    >
      <AdminPaginatedList
        title='Pending Groups'
        url={`/admin/user-groups/pending`}
        columns={['name', 'description']}
        data={pendingContent}
        isLoading={pendingGroups.isLoading}
        itemTemplate={itemTemplate}
      />
      <AdminPaginatedList
        columns={['name', 'description']}
        data={activeContent}
        url={`/admin/user-groups`}
        isLoading={activeGroups.isLoading}
        itemTemplate={itemTemplate}
      />
    </AdminPage>
  );
};

export default UserGroupList;
