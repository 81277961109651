/* eslint-disable react/display-name */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminModal from '../../../components/AdminModal';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import AdminRecord from '../../../components/AdminRecord';
import { AdminToastTimed } from '../../../components/AdminToasts';
import { uuidv4 } from '../../../utils/uuid';

const AdminServiceDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location: any = useLocation();
  const { adminApi } = useBlueprintAdmin();

  const getContent = (content: any) => {
    if (typeof content === 'object' && content !== null) {
      if (content.length > 0 && typeof content[0] !== 'object') {
        return content.join('; ');
      } else {
        // admin groups which is an array of objects
        return content.map((x) => x.name).join('; ');
      }
    } else {
      return typeof content === 'boolean' ? (content ? 'Yes' : 'No') : content;
    }
  };

  const itemTemplate = (data) => (
    <AdminCard title='Account'>
      <Table>
        <TableBody>
          {Object.entries(data).map(([key, value]: any) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell data-testid={key}>{getContent(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AdminCard>
  );

  return (
    <>
      {location.state?.detail === 'newAdmin' && (
        <AdminToastTimed
          message={'Admin successfully created'}
          trigger={location.state?.detail === 'newAdmin' ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}

      {location.state?.detail === 'newService' && (
        <AdminModal
          title={`Save your service API Key now`}
          body={
            <>
              <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                You will only see this token once
              </Typography>
              <Typography variant='body2'>
                A new API token has been created for use with your service
                account. This token will only be displayed to you once, so
                please save it immediately for usage.
              </Typography>

              <Typography
                variant='body1'
                style={{ fontWeight: 'bold', marginTop: 15 }}
              >
                Saving the API token
              </Typography>
              <Typography variant='body2'>
                Your token is visible in the field below, by clicking on this
                field it will be automatically copied to your clipboard.
              </Typography>
              <div
                contentEditable='false'
                suppressContentEditableWarning={true}
                style={{
                  height: 'auto',
                  width: '100%',
                  padding: 10,
                  marginTop: 10,
                  borderColor: '#ccc',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  backgroundColor: '#f0ecec',
                  overflow: 'scroll',
                }}
                onClick={() => {
                  void navigator.clipboard.writeText(location.state?.token);
                  navigate('./', {
                    state: { ...location.state, action: 'CopiedToClipBoard' },
                  });
                }}
              >
                {location.state?.token}
              </div>

              <Typography
                variant='body1'
                style={{ fontWeight: 'bold', marginTop: 15 }}
              >
                Using the API token
              </Typography>
              <Typography variant='body2'>
                This token should be only used for system to system
                communication (e.g. not used by a person). You can use it
                through the Blueprint SDK by setting it in the{' '}
                <code>APIConfig</code> settings. Or you can provide it directly
                to the API by providing this token in the HTTP header of{' '}
                <code>X-API-Key</code>
              </Typography>

              <Typography
                variant='body1'
                style={{ fontWeight: 'bold', marginTop: 10 }}
              >
                Getting another API token
              </Typography>
              <Typography variant='body2'>
                If you need to get this token again or if you make changes to
                the roles/permissions of this service account, you will need to
                click <strong>Generate New Token</strong> at the top of this
                page to create a new one.
              </Typography>

              {location.state?.action === 'CopiedToClipBoard' && (
                <AdminToastTimed
                  message={'API token copied to clipboard'}
                  trigger={
                    location.state?.action === 'CopiedToClipBoard'
                      ? uuidv4()
                      : null
                  }
                  time={3000}
                  severity={'success'}
                ></AdminToastTimed>
              )}
            </>
          }
          actions={
            <>
              <Button
                variant='text'
                color='primary'
                onClick={() =>
                  navigate('./', {
                    state: { detail: 'done' },
                  })
                }
              >
                Done
              </Button>
            </>
          }
          isOpen={location.state?.detail === 'newService'}
          closeModal={() =>
            navigate('./', {
              state: { detail: 'done' },
            })
          }
          size='sm'
          fullWidth={true}
          type='dialog'
        />
      )}
      <AdminPage
        title={`Admin Service Account`}
        editAction={`/admins/service/${id}/edit`}
        customActionText='Generate New Token'
        customAction={async () => {
          const response = await adminApi?.patch({
            path: `/admin/admins/${id}`,
            body: {},
          });
          if (response) {
            navigate(`/admins/service/${response.data.id}`, {
              state: { detail: 'newService', token: response.data.apiToken },
            });
          }
        }}
      >
        <AdminRecord url={`/admin/admins/${id}`} itemTemplate={itemTemplate} />
      </AdminPage>
    </>
  );
};

export default AdminServiceDetail;
