// import cubejs from '@cubejs-client/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { traits } from '../../../../../src/config/traits';
import AdminModal from '../../../../components/AdminModal';
import { useBlueprintAdmin } from '../../../../components/AdminProvider';
import { AdminToastTimed } from '../../../../components/AdminToasts';
import { FormInputMultiCheckbox } from '../form-components/FormInputMultiCheckbox';
import { FormInputText } from '../form-components/FormInputText';

interface DestinationSelector {
  beaconKey: string;
  destinations: any[];
  children: any;
  onSelect?: any;
  id?: string;
  obj?: any;
  onChange?: any;
}

interface DestinationFormFields {
  platformId: string | null;
  destinationId: number | null;
  name: string | null;
  accessToken: string | null;
  blockedEvents: Array<string> | null;
  allowedPii: Array<string> | null;
}

const DestinationSelectorCard = ({
  beaconKey,
  destinations,
  obj,
  onChange,
}: DestinationSelector) => {
  const methods = useForm<DestinationFormFields>({
    defaultValues: obj,
  });

  const { control, setValue, getValues } = methods;
  const { config, adminDestinationApi } = useBlueprintAdmin();
  const [eventValues, setEventValues] = useState<Array<any>>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>();
  const [destination, setDestination] = useState(obj);

  // const cubejsApi = cubejs(config.reportingKey, {
  //   apiUrl: config.apiRoot + '/admin/reporting/v1',
  // });

  // useEffect(() => {
  //   let isMounted = true;
  //   cubejsApi
  //     .load({
  //       measures: ['Activity.count'],
  //       timeDimensions: [
  //         {
  //           dimension: 'Activity.sentAt',
  //         },
  //       ],
  //       order: {
  //         'Activity.count': 'desc',
  //       },
  //       filters: [
  //         {
  //           member: 'Activity.beaconKey',
  //           operator: 'equals',
  //           values: [beaconKey],
  //         },
  //       ],
  //       dimensions: ['Activity.event'],
  //     })
  //     .then((data) => {
  //       if (isMounted) {
  //         setEventValues(data.chartPivot());
  //       }
  //     })
  //     .catch((err) => console.error(err));
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const onSubmit = async (data, action) => {
    let response;
    if (action === 'save') {
      response = await adminDestinationApi!.update(data.id, data);
      setDestination(response.data);
    } else {
      response = await adminDestinationApi!.delete({
        path: `/admin/destinations/${data.id}`,
      });
      const updatedDestinations = destinations.map((item: any) => {
        if (item.id === response.data.id) {
          return response.data;
        }
        return item;
      });
      try {
        onChange(
          updatedDestinations.filter((item) => item.status === 'active')
        );
      } catch {
        console.error('error updating destinations after deletion');
      }
    }

    if (response.success) {
      setIsSaved(!isSaved); // trigger for admin toast
    } else {
      console.error('error');
    }
    setIsModalOpen(false);
  };

  const DestinationEditForm = () => {
    const DestinationForm = () => {
      const blockedEvents = eventValues.map((item): any => ({
        label: `${item.x} (Occurred ${item['Activity.count']} times)`,
        value: item.x,
      }));
      const allowedPii = traits.map((trait) => ({
        label: trait,
        value: trait,
      }));

      return (
        <Grid container spacing={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>
                Destination Name: (Current: {destination.name})
              </Typography>
              <FormInputText
                name='name'
                control={control}
                label='ex: FB Form Destinations'
                widthvar='75%'
                setValue={setValue}
                submitfunction={() => {
                  return;
                }}
                testId='name-input'
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>
                Platform ID (Current: {destination.platformId})
              </Typography>
              <FormInputText
                name='platformId'
                control={control}
                label='ex: facebook, twitter'
                widthvar='75%'
                setValue={setValue}
                submitfunction={() => {
                  return;
                }}
                testId='platformId-input'
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Access Token</Typography>
              <FormInputText
                name='accessToken'
                control={control}
                label='ex: 131234adaef3324r'
                widthvar='75%'
                setValue={setValue}
                submitfunction={() => {
                  return;
                }}
                testId='accessToken-input'
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>
                Destination (Current: {destination.destinationId})
              </Typography>
              <FormInputText
                name='destinationId'
                control={control}
                label='ex: 123456789012345'
                widthvar='75%'
                setValue={setValue}
                submitfunction={() => {
                  return;
                }}
                testId='destinationId-input'
              />
            </Grid>
          </Grid>
          <Grid container>
            <Divider style={{ width: '100%', marginTop: '10px' }} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Blocked Events</Typography>
              <FormInputMultiCheckbox
                name={'blockedEvents'}
                control={control}
                label={'Text goes here explaining each option'}
                setValue={setValue}
                optionsList={blockedEvents.map((value) => {
                  return value.label.length > 60
                    ? { ...value, label: value.label.substring(0, 60) }
                    : value;
                })}
                defaultValues={destination.blockedEvents}
                submitfunction={() => {
                  return;
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Allowed pii</Typography>
              <FormInputMultiCheckbox
                name={'allowedPii'}
                control={control}
                label={'Text goes here explaining each option'}
                setValue={setValue}
                optionsList={allowedPii}
                defaultValues={destination.allowedPii}
                submitfunction={() => {
                  return;
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                onClick={() => onSubmit(getValues(), 'save')}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                variant='contained'
                color='primary'
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    };
    return <DestinationForm></DestinationForm>;
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography variant='h6'>
            Destination Name: {destination.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DestinationEditForm></DestinationEditForm>
        </AccordionDetails>
      </Accordion>
      <AdminToastTimed
        message={'Destination settings have been saved'}
        trigger={isSaved}
        time={3000}
        severity={'success'}
      ></AdminToastTimed>
      <AdminModal
        title={`Delete Destination?`}
        body={
          <Typography variant='body1'>
            Are you sure you would like to delete this destination?
          </Typography>
        }
        actions={
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => setIsModalOpen(false)}
            >
              CANCEL
            </Button>
            <Button
              variant='text'
              color='primary'
              onClick={() => onSubmit(getValues(), 'delete')}
            >
              DELETE
            </Button>
          </>
        }
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
    </>
  );
};

export default DestinationSelectorCard;
