import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../components/AdminProvider';

const ProspectMatch = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { adminUserApi } = useBlueprintAdmin();
  const [users, setUsers] = useState([]);

  const onSuccess = () => {
    navigate(`/prospects`);
  };

  const onFailure = (data) => {
    console.error(data);
  };

  useEffect(() => {
    adminUserApi
      ?.getUsers({ limit: 9999, page: 1 })
      .then(({ data }) => {
        const response = data.map((user) => ({
          type: 'string',
          enum: user,
          enumNames: user.username,
        }));
        setUsers(response);
      })
      .catch((err) => console.error(err));
  }, []);

  function validate(formData, errors) {
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword.addError("Passwords don't match");
    }
    return errors;
  }

  const uiSchema = {
    userId: { 'ui:widget': 'autoComplete' },
  };

  const schema = {
    description: 'Matching a prospect',
    type: 'object',
    properties: {
      userId: {
        type: 'string',
        title: 'User',
        enum: users.map((user: any) => user.enum.id),
        enumNames: users.map((user: any) => user.enumNames),
        description: 'The user we are matching the prospect to',
      },
      profileTrait: {
        type: 'object',
        properties: {
          key: {
            type: 'string',
            title: 'Key',
            description:
              'What is the trait of the user we trying to match (ie: "email")',
          },
          value: {
            type: 'string',
            title: 'Value',
            description:
              'The value of the user trait we are trying to match (ie: "user@email.com")',
          },
        },
      },
      prospectKey: {
        type: 'string',
        title: 'Prospect Key',
        description:
          'The prospect trait key we are using to match to a user (ie: "email")',
      },
    },
  };

  return (
    <>
      {id && (
        <>
          <AdminBaseForm
            url='/admin/prospects/match'
            entityTitle='Prospects'
            schema={schema}
            uiSchema={uiSchema}
            onSuccess={onSuccess}
            onFailure={onFailure}
            validate={validate}
          />
        </>
      )}
    </>
  );
};

export default ProspectMatch;
