import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { Grid } from '@mui/material';
import AdminCard from './AdminCard';
import AdminForm from './AdminForm';
import AdminPage from './AdminPage';
import { useBlueprintAdmin } from './AdminProvider';

function AdminBaseForm({
  url,
  schema,
  uiSchema = {},
  entityTitle = undefined,
  id = undefined,
  onSubmit,
  onSuccess,
  onFailure,
  rootValue = undefined,
  showSidebar = true,
  formData = undefined,
  breadcrumbs,
  validate = true,
}: any) {
  const entityUrl = id === undefined ? url : `${url}/${id}`;
  const { config }: any = useBlueprintAdmin();
  const api = new AdminScopedAPI(config, 'AdminBaseForm');

  const submitAction = async ({ formData }, e) => {
    if (onSubmit) {
      await onSubmit({ formData }, e);
    } else {
      let data = formData;
      if (data.status === 'published' && !data.created_at) {
        data.created_at = new Date().getTime();
      }
      // For collections only, but if we have a schema field, let's convert it to JSON from the string
      // if (data.schema) {
      //   data.schema = JSON.parse(data.schema);
      // }
      // // For collections only, but if we have a schema field, let's convert it to JSON from the string
      // if (data.uiSchema) {
      //   data.uiSchema = JSON.parse(data.uiSchema);
      // }
      if (rootValue) {
        data = data[rootValue];
      }
      // If an ID is not present then POST, otherwise PATCH
      const responseBody = {
        path: entityUrl,
        body: data,
      };
      const response =
        id === undefined
          ? await api.post(responseBody)
          : await api.patch(responseBody);

      if (response.success) {
        onSuccess(response);
      } else {
        onFailure(response);
      }
    }
  };

  return (
    <AdminPage
      showSidebar={showSidebar}
      title={entityTitle ? entityTitle : undefined}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <AdminCard>
          <AdminForm
            isCreate={id === undefined}
            url={id === undefined ? undefined : `${entityUrl}`}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={submitAction}
            validate={validate}
            formData={formData ? formData : undefined}
          />
        </AdminCard>
      </Grid>
    </AdminPage>
  );
}

export default AdminBaseForm;
