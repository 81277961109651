import Icon from '@mui/material/Icon';

interface DynamicIconProps {
  iconName: string;
  className: string;
}

function sentenceCaseToSnakeCase(input: string): string {
  // Replace the first character with its lowercase version
  let result = input.charAt(0).toLowerCase() + input.slice(1);

  // Replace uppercase characters with underscores followed by their lowercase version
  result = result.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);

  return result;
}

const DynamicIcon = ({ iconName, className }: DynamicIconProps) => {
  return (
    <Icon className={className} sx={{ transform: 'scale(1.25)' }}>
      {sentenceCaseToSnakeCase(iconName)}
    </Icon>
  );
};

export default DynamicIcon;
