import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBeaconConfig } from '../../../../api/interfaces';
import { useBlueprintAdmin } from '../../../../components/AdminProvider';
import AdminTableCard from '../../../../components/AdminTableCard';
import { uuidv4 } from '../../../../utils/uuid';
import BeaconGridSettingsCard from '../BeaconGridSettingsCard';
import { DefaultBeaconSettingOptions } from './BeaconSettingConstants';
import {
  setActivityPreviousConfig,
  userActivitySetting,
} from './beaconSettingComponents/activitySettingComponent';
import {
  setConfigurationConfig,
  userConfigurationSetting,
} from './beaconSettingComponents/configurationComponent';
import {
  setStoreConfig,
  userStoreSetting,
} from './beaconSettingComponents/storeSettingComponent';

const BeaconSettingGrid = ({
  beacon,
  displayType,
  setSelectedDetailTab,
}: any) => {
  const [savedBeaconSettings, setSavedBeaconSettings] = useState<any>({});
  useEffect(() => {
    setSelectedDetailTab('BeaconSettings');
  }, []);

  useEffect(() => {
    setSavedBeaconSettings(beacon?.settings ?? {});
  }, [beacon]);

  const [beaconOptions, setBeaconOptions] = useState<any>([
    ...DefaultBeaconSettingOptions,
  ]);
  const [gridDisplayType, setGridDisplayType] = useState(displayType);
  const [specificBeaconSetting, setSpecificBeaconSetting] = useState(null);
  const { config, adminActivityApi }: any = useBlueprintAdmin();
  const navigate = useNavigate();

  const useStyles = makeStyles((theme: Theme) => ({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (specificBeaconSetting) {
      const filteredDefaultOptions = DefaultBeaconSettingOptions.filter(
        (value) => value.id === specificBeaconSetting
      );
      setBeaconOptions(filteredDefaultOptions);
      setGridDisplayType('Card');
      setupPrevConfig(filteredDefaultOptions);
    } else {
      const newlist = [...DefaultBeaconSettingOptions];
      setupPrevConfig(newlist);
    }
  }, [savedBeaconSettings, specificBeaconSetting]);

  const onBackToBeaconSettings = async () => {
    setSpecificBeaconSetting(null);
    setGridDisplayType('Table');
    if (adminActivityApi) {
      const response = await adminActivityApi.getBeacon(beacon.id);
      if (response.success) {
        setSavedBeaconSettings(response.data.settings ?? []);
      } else {
        console.error('error getting beacon');
      }
    }
  };

  const onSave = async () => {
    const data: IBeaconConfig = {
      settings: savedBeaconSettings,
    };
    if (adminActivityApi) {
      const response = await adminActivityApi.updateBeacon(beacon.id, data);
      if (response.success) {
        navigate('./', {
          state: { detail: 'BeaconSettingSaved'.concat(uuidv4()) },
        });
      } else {
        console.error('error saving beacon');
      }
    }
  };

  const setupPrevConfig = (options) => {
    const updatedOptions = [...options];
    if (savedBeaconSettings) {
      Object.keys(savedBeaconSettings).forEach((saved) => {
        options.map((option, i) => {
          if (saved === option.id) {
            let updatedObj = {};
            updatedObj = { ...option, unique_id: uuidv4() };
            const name = option.id;
            switch (name) {
              case 'activity':
                updatedObj = {
                  ...updatedObj,
                  form_data: setActivityPreviousConfig(
                    savedBeaconSettings,
                    name
                  ),
                };
                break;
              case 'configuration':
                updatedObj = {
                  ...updatedObj,
                  form_data: setConfigurationConfig(savedBeaconSettings, name),
                };
                break;
              case 'store':
                updatedObj = {
                  ...updatedObj,
                  form_data: setStoreConfig(savedBeaconSettings, name),
                };
                break;
            }
            updatedOptions[i] = updatedObj;
          }
        });
      });
    }
    setBeaconOptions(updatedOptions);
  };

  const onFormChange = (form_data, id) => {
    const newOptions = savedBeaconSettings;
    let settingsObj: any = null;
    for (const x of Object.keys(savedBeaconSettings)) {
      switch (id) {
        case 'activity': {
          settingsObj = userActivitySetting(form_data);
          break;
        }
        case 'configuration': {
          settingsObj = userConfigurationSetting(form_data);
          break;
        }
        case 'store': {
          settingsObj = userStoreSetting(form_data);
          break;
        }
        default:
          settingsObj = form_data;
      }
      if (settingsObj) {
        newOptions[id] = settingsObj;
      } else {
        delete newOptions[id];
      }
    }
    setSavedBeaconSettings(newOptions);
  };

  return (
    <>
      {gridDisplayType === 'Card' && (
        <>
          {beaconOptions.map(
            ({
              id,
              unique_id,
              title,
              description,
              form_data,
              disableToggle,
            }) => (
              <Grid style={{ paddingTop: 20 }} key={unique_id}>
                <BeaconGridSettingsCard
                  cardType={'Beacon'}
                  showSettings={true}
                  key={unique_id}
                  id={id}
                  onFormChange={(form_data, id) => onFormChange(form_data, id)}
                  beacon={beacon}
                  selectable={true}
                  title={title}
                  form_data={form_data}
                  disableToggle={disableToggle}
                >
                  <p>{description}</p>
                </BeaconGridSettingsCard>
              </Grid>
            )
          )}
        </>
      )}
      {gridDisplayType === 'Table' && (
        <>
          {beaconOptions.map(
            ({ id, unique_id, title, description, tooltip, form_data }) => (
              <Grid style={{ paddingTop: 20 }} key={unique_id}>
                <AdminTableCard
                  key={unique_id}
                  id={id}
                  onFormChange={(form_data, id) => onFormChange(form_data, id)}
                  onSelectSpecific={(specificTracker) => {
                    setSavedBeaconSettings(
                      savedBeaconSettings[specificTracker]
                        ? savedBeaconSettings
                        : { ...savedBeaconSettings, [specificTracker]: {} }
                    );
                    setSpecificBeaconSetting(specificTracker);
                  }}
                  beacon={beacon}
                  selectable={true}
                  title={title}
                  toolTip={tooltip}
                  form_data={form_data}
                >
                  <p>{description}</p>
                </AdminTableCard>
              </Grid>
            )
          )}
        </>
      )}
      {specificBeaconSetting && (
        <Grid container xs={12}>
          <Grid container xs={10} justifyContent='flex-start'>
            <Button
              variant='text'
              color='primary'
              onClick={async () => {
                await onBackToBeaconSettings();
              }}
              className={classes.button}
            >
              Back To All Settings
            </Button>
          </Grid>
          <Grid container xs={2} justifyContent='flex-end'>
            <Grid>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => await onSave()}
                className={classes.button}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BeaconSettingGrid;
