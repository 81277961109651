import { schema, uiSchema } from '../../forms/jobs';
import { useNavigate } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';

const JobNew = () => {
  const navigate = useNavigate();

  const onSuccess = (response) => {
    navigate(`/jobs/${response.data.id}`);
  };

  return (
    <AdminBaseForm
      url='/admin/schedules'
      entityTitle='Jobs'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default JobNew;
