import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminTabs from '../../components/AdminTabsUnboxed';
import { AdminToastTimed } from '../../components/AdminToasts';
import { uuidv4 } from '../../utils/uuid';
import ActivityDebugger from './BeaconActivityDebuggerView';
import { InstallBeacon } from './BeaconScript';
import Triggers from './Triggers';
import BeaconHeaderCard from './edit/BeaconHeaderCard';
import BeaconSettingGrid from './edit/beaconSettings/beaconSettingGrid';
import BeaconEditDestinations from './edit/destinationSettings/destination';
import BeaconEditTrackers from './edit/trackerSettings/trackers';

const ConfigBeaconDetail = () => {
  const { id } = useParams<{ id: string }>();
  const location: any = useLocation();
  const { config, jwtToken, adminActivityApi } = useBlueprintAdmin();
  const [beacon, setBeacon] = useState<any | undefined>(undefined);
  const [toastController, setToastController]: any = useState(null);
  const [selectedDetailTab, setSelectedDetailTab] = useState('');
  const [fromDestinations, setFromDestinations] = useState<boolean>(false);

  useEffect(() => {
    if (location.state?.lastPage === 'destinations') {
      setFromDestinations(true);
    }
  }, [location.state?.lastPage]);

  useEffect(() => {
    setToastController(location.state?.detail ?? '');
  }, [location.state?.detail]);

  useEffect(() => {
    setToastController('');
    adminActivityApi!
      .getBeacon(id!)
      .then(({ data }) => setBeacon(data))
      .catch((e) => console.error(e));
  }, [selectedDetailTab]);

  useEffect(() => {
    adminActivityApi!
      .getBeacon(id!)
      .then(({ data }) => setBeacon(data))
      .catch((e) => console.error(e));
  }, []);

  return (
    <>
      {location.state?.detail === 'newBeacon' && (
        <AdminToastTimed
          message={
            'Beacon successfully created, Install the beacon to start collecting data'
          }
          trigger={location.state?.detail === 'newBeacon' ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      {location.state?.lastPage === 'destinations' && (
        <AdminToastTimed
          message={'Destination successfully set'}
          trigger={
            location.state?.lastPage === 'destinations' ? uuidv4() : null
          }
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      {toastController?.includes('BeaconTrackerSaved') && (
        <AdminToastTimed
          message={'Tracker settings have been saved'}
          trigger={
            toastController?.includes('BeaconTrackerSaved') ? uuidv4() : null
          }
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      {toastController?.includes('CopiedToClipBoard') && (
        <AdminToastTimed
          message={'Beacon info copied to clipboard'}
          trigger={
            toastController?.includes('CopiedToClipBoard') ? uuidv4() : null
          }
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      {toastController?.includes('BeaconSettingSaved') && (
        <AdminToastTimed
          message={'Beacon settings have been saved'}
          trigger={
            toastController?.includes('BeaconSettingSaved') ? uuidv4() : null
          }
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      {id && beacon && (
        <AdminPage animation={true} title={`Manage Beacon`}>
          {beacon.status === 'active' ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant='text'
                  color='primary'
                  to={`/beacons`}
                  style={{ padding: '0', marginBottom: '20px' }}
                  component={Link}
                >
                  <ArrowBackIcon />
                  &nbsp;&nbsp;ALL BEACONS
                </Button>
                <BeaconHeaderCard
                  beacon={beacon}
                  setBeacon={setBeacon}
                  id={id}
                />
              </Grid>
              <Grid item xs={12}>
                <AdminCard title='Beacon Settings'>
                  <Grid container style={{ width: '100%' }}>
                    <AdminTabs
                      removeTitle={true}
                      defaultTab={fromDestinations ? 4 : 0} // if coming from destinations set the default tab to index 4... may need a better way for this
                      tabs={[
                        {
                          title: 'Activity Debugger',
                          template: (
                            <ActivityDebugger
                              beacon={beacon}
                            ></ActivityDebugger>
                          ),
                        },
                        {
                          title: 'Tracker Settings',
                          template: (
                            <>
                              <Typography
                                variant='h5'
                                style={{
                                  flex: 1,
                                  paddingBottom: 20,
                                  paddingTop: 20,
                                }}
                              ></Typography>
                              <BeaconEditTrackers
                                beacon={beacon}
                                setSelectedDetailTab={setSelectedDetailTab}
                              />{' '}
                            </>
                          ),
                        },
                        {
                          title: 'Triggers',
                          template: (
                            <>
                              <Triggers beaconKey={beacon.key} />
                            </>
                          ),
                        },
                        {
                          title: 'Install Beacon',
                          template: (
                            <InstallBeacon
                              beacon={beacon}
                              isDevelopment={beacon.debug}
                              setSelectedDetailTab={setSelectedDetailTab}
                            ></InstallBeacon>
                          ),
                        },
                        {
                          title: 'Set Destinations',
                          template: (
                            <Grid style={{ marginTop: 15 }}>
                              <AdminCard
                                title='Click on a destination to modify or delete it. Click New + to add a new destination.'
                                newAction={`/beacons/${beacon.id}/destinations/new`}
                              >
                                <BeaconEditDestinations
                                  beacon={beacon}
                                  setSelectedDetailTab={setSelectedDetailTab}
                                ></BeaconEditDestinations>
                              </AdminCard>
                            </Grid>
                          ),
                        },
                        {
                          title: 'Advanced Settings',
                          template: (
                            <>
                              <Typography
                                variant='h5'
                                style={{
                                  flex: 1,
                                  paddingBottom: 20,
                                  paddingTop: 20,
                                }}
                              ></Typography>
                              <BeaconSettingGrid
                                beacon={beacon}
                                displayType={'Table'}
                                setSelectedDetailTab={setSelectedDetailTab}
                              />{' '}
                            </>
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </AdminCard>
              </Grid>
            </Grid>
          ) : (
            <Typography variant='h6'>This beacon does not exist.</Typography>
          )}
        </AdminPage>
      )}
    </>
  );
};

export default ConfigBeaconDetail;
