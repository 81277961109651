import { useNavigate } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { schema } from '../../../forms/adminGroups';

const AdminGroupNew = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(`/admins`, {
      state: { detail: 'newAdminGroup' },
    });
  };

  return (
    <AdminBaseForm
      url='/admin/groups'
      entityTitle='Admin Group'
      schema={schema}
      onSuccess={onSuccess}
    />
  );
};

export default AdminGroupNew;
